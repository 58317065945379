import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import StockMaterialPrint from './StockMaterialPrint';
import {Table, PrintPreview} from '../../components'

import {getAPI, deleteAPI, checkUserRoles} from '../../utils/';

import moment from 'moment'

const columns = (tipo) => {
  return [
    {
      name: 'TIPO',
      field: v => v.tipo,
      width: 50,
    },
    {
      name: 'OBRA',
      field: v => v.obra && `[${v.obra.carpeta}] ${v.obra.nombre}`,
      width: 50,
      hide: tipo !== 'ENV' && tipo !== 'DEV' 
    },
    {
      name: 'OF',
      field: v => v.of && v.of.tipo === 'fabrica' && v.of.of,
      width: 50,
      hide: tipo !== 'ENV' && tipo !== 'DEV' 
    },
    {
      name: 'OS',
      field: v => v.of && v.of.tipo === 'servicio' && v.of.of,
      width: 50,
      hide: tipo !== 'ENV' && tipo !== 'DEV' 
    },
    {
      name: 'RECURSO',
      field: v => v.of && v.of.recurso || v.of.recursos,
      width: 50,
      hide: tipo !== 'ENV' && tipo !== 'DEV' 
    },
    {
      name: 'PO',
      field: v => v.comprobante && v.comprobante.pedido_orig && v.comprobante.pedido_orig._id && v.comprobante.pedido_orig._id.np,
      width: 50,
      hide: tipo !== 'VEN-STK'
    },
    {
      name: 'CODIGO',
      field: v => v.articulo.codigo,
      width: 50,
    },
    {
      name: 'ARTICULO',
      field: v => v.articulo.descripcion,
      width: 50,
    },
    {
      name: 'COLOR',
      field: v => v.articulo_color,
      width: 50,
    },
    {
      name: 'ALMACEN',
      field: v => v.almacen_salida && `${v.almacen_salida.codigo}  ${v.ubi1 || ""}/${v.ubi2 || ""}/${v.ubi3 || ""}`,
      width: 50,
    },
    {
      name: 'CANTIDAD',
      field: v => v.recibido,
      width: 50,
    },
    {
      name: 'UDM',
      field: v => v.udm,
      width: 50,
    },
  ]
}

const StockMaterialTable = ({tipo, lista, progress}) => {
  return (
    <>
      <Table 
        //sx={{width: "800px", p:1, m:1}}
        loading={progress}
        cols={columns(tipo)}
        data={lista}

        tableProps={{
            size:'small', padding: 'none', stickyHeader: true
        }}
        headerProps={{
            sx: { 
                th: {
                    fontSize: 13, 
                    fontWeight:'bold', 
                    color:'white', 
                    backgroundColor: "#549857", //green[500], 
                    textAlign: 'center', 
                    borderRight: '1px solid #fff',
                    p: '2px 5px'
                }
            }
        }}
        rowProps={{
            sx: {
                m:1, 'td': {fontSize: '12px', p: '0px 10px'}
            }
        }}
      />

      {lista.length && 
          <Box sx={{p:1, float:'right', fontSize: 12}}>
            por {lista[0].ownerId.username} el {moment(lista[0].created_at).format("YYYY-MM-DD HH:MM")}
          </Box>
      }
    </>

  )
}

const StockMaterialDialog = ({tipo, comprobante, open=true, onCancel}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [lista, setLista] = useState([]);
    const [progress, setProgress] = useState(false);

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['admin', 'local-admin', 'gerencia'])
    
    let title, url, qparams;
    if (tipo === 'ENV' || tipo === 'DEV') {
      url = '/stock/workorder_materials/' + comprobante;
      title = "MOVIMIENTO DE MATERIAL"
    }
    // if (tipo === 'VEN-STK') {
    //   url = '/stock/movements';
    //   qparams = {tipo, comprobante};
    //   title = "MOVIMIENTO DE ALMACEN"
    // }

    useEffect( () => {
      if (!url) {
        enqueueSnackbar("[stockMaterialDialog] la ruta esta vacia");
        return
      }
      setProgress(true)
      getAPI(url, qparams)
      .then(res => {
        debugger
        setProgress(false);
        setLista(res.list);
      })
      .catch(err => {
        enqueueSnackbar(err.message, {variant: 'error'})
      })

    }, [comprobante, enqueueSnackbar, url, qparams])

    const handleDelete = () => {
      if (tipo === 'ENV' || tipo === 'DEV') {
        setProgress(true)
        deleteAPI(url)
        .then(data => {
          setProgress(false);
          enqueueSnackbar("Se borraron " + data.num + " elementos", {variant: 'info'})
          if (onCancel) onCancel(true)
        })
        .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
        })
      }
    }

    if (!url) {
      return ""
    }

    return (
        <Dialog
          disableBackdropClick
          //disableEscapeKeyDown
          open={open}
          maxWidth='md'
          fullWidth={true}
          onClose={onCancel}
        >
          <DialogTitle>
              Movimiento de Material {tipo}/{comprobante}
              {esLocalAdmin && <Button sx={{float:'right'}} variant='outlined' onClick={handleDelete}>Borrar</Button>}
              <PrintPreview 
                sx={{float:'right', color: 'inherit'}} 
                component={
                  <StockMaterialPrint doc={{
                    tipo, comprobante, 
                    component: <StockMaterialTable tipo={tipo} lista={lista} progress={progress}/>
                    }}
                  />
                }
              />
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>


              <StockMaterialTable tipo={tipo} lista={lista} progress={progress}/>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} disable={!progress} color="primary">
              OK
            </Button>
            {/* <Button onClick={handleSubmit} disable={!progress} color="primary">
              Guardar
            </Button> */}
          </DialogActions>
        </Dialog>
    )
}


export default StockMaterialDialog;
