import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useParams, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Table, MessageBox} from '../../components';
import {getAPI, postAPI, deleteAPI, activityLog} from '../../utils';
import MeasurementForm from './MeasurementForm';
import {SERVER_ATTACHMENT_PATH} from '../../appConfig.js';
import moment from 'moment';



export const MeasurementTable = ({editable=false, disabled=false, obraId, pvId, docs=[], refe={}, onReload=()=>{}}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(false);
    const [dialogDelete, setDialogDelete] = useState(false);
    const org = useSelector(state => state.org);
    const currentUser = useSelector(state => state.currentUser);

    const deleteMeasurement = (id) => {
        return deleteAPI('/measurements/' + id)
            .then(async ({res, doc, num}) => {
                debugger
                let msg = `Se borró el archivo de medición "${doc.archivo.filename}". `

                if (num === 0) {
                    await postAPI('/project/'+obraId, {status: 'SP', fecha_medicion: null})
                    msg += "Se cambio de estado a SP y se borro fecha de medición."
                    // activityLog({label: refe['obra'].nombre, app:'obras', docId: refe['obra']._id, msg: msg, currentUser})
                }
                activityLog({label: refe['obra'].nombre, app:'obras', docId: refe['obra']._id, msg, currentUser})
        
                enqueueSnackbar(msg, {variant: 'info'})
                if (onReload) onReload()
            })
            .catch(err => {
                console.log(err)
                enqueueSnackbar(err.message, {variant: 'error'})
            })
    }
    const handleEdit = (doc) => (ev) => {
        setEdit(doc)
    }
    const handleDeleteVerification = (_id) => (ev) => {
        setDialogDelete(_id)
    }
    const handleDelete = async (vale) => {
        debugger
        if (vale) {
            await deleteMeasurement(dialogDelete)
        }
        setEdit({})
        setDialogDelete("")
    }
    const handleCancel = (ev) => {
        setEdit({})
    }

    const cols = useMemo(() => [
        {   name: 'Fecha aprobación', 
            field: v=> v['fecha_aprobacion'] && moment(v['fecha_aprobacion']).utc().format('YYYY-MM-DD'),    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Fecha entrega',    
            field: v=> v['fecha_entrega'] && moment(v['fecha_entrega']).utc().format('YYYY-MM-DD'),    
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: "Archivo", 
            field: v=> {
                const archivo = v['archivo'];
                if (archivo) {
                    const href = SERVER_ATTACHMENT_PATH + "/" + org + "/" + archivo['path'] + archivo['filename'];
                    return <a href={href} target="_blank">{archivo['filename']}</a>;
                    // return <Link to={href}>{archivo['filename']}</Link>
                }
            },
            th: { align:'center'},
            td: { align:'center'}, 
        },
        {   name: 'Subido por', 
            field: v=> v['approvedById'] && v['approvedById']['username'],    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Actualizado',    
            field: v=> v['updated_at'] && moment(v['updated_at']).format('YYYY-MM-DD'),    
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {
            name: 'Acciones',
            field: v=> {
                return (
                    <>
                    <IconButton aria-label="Edit" disabled={disabled} onClick={handleEdit(v)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="Delete" disabled={disabled} onClick={handleDeleteVerification(v._id)}>
                        <DeleteIcon />
                    </IconButton>
                    </>

                )
            },
            hide: !editable
        }
    ], [org, editable])
    

    return (
        <Box>
            <Table 
                //sx={{width: "800px", p:1, m:1}}
                cols={cols}
                data={docs}
                tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
                headerProps={{sx: {fontWeight:'bold'}}}
                rowProps={{sx: {m:1}}}
            />

            {editable && <MeasurementForm obraId={obraId} pvId={pvId} doc={edit} refe={refe} disabled={disabled} onReload={onReload} onCancel={handleCancel}/>}

            <MessageBox 
                title="Esta seguro que quiere borrar esta Medición?"
                open={dialogDelete} 
                onClose={handleDelete}
            />

        </Box>
    )
}

export default MeasurementTable