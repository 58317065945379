import React, {useEffect, useState, useContext} from 'react';
import { TabsContext } from '../Layout/TabsProvider'; 
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableTitle } from '../../components';

import {getAPI} from '../../utils' ;
import { fontWeight } from '@mui/system';

import moment from 'moment';

const OpportunitySales = ({reload, ...other}) => {
    const [loading, setLoading] = useState(false);
    const [sales, setSales] = useState([])
    const [sumsPer, setSumsPer] = useState({})
    const [sumsVend, setSumsVend] = useState({})
    const [counts, setCounts] = useState({})
    const [countsVend, setCountsVend] = useState({})
    const [periodos, setPeriodos] = useState([])
    const [vendedores, setVendedores] = useState([])
    const {dispatchTabs} = useContext(TabsContext);
    const dispatch = useDispatch();

    useEffect( () => {
        setLoading(true)
        getAPI('/crm/oppos/statsSales', {})
        .then(data => {
            debugger
            setLoading(false);
            const {sales, periodos, vendedores, sumsPer, sumsVend, counts, countsVend} = data;
            setSales(sales)
            setPeriodos(periodos)
            setVendedores(vendedores)
            setSumsPer(sumsPer)
            setSumsVend(sumsVend)
            setCounts(counts)
            setCountsVend(countsVend)
        })
        .catch(err => {
            console.error(err)
        });
    }, [reload])

    const handleGoto = (periodo, responsable="-1") => ev => {
        debugger
        const fecha_desde = periodo ? moment(periodo, 'M-YYYY').startOf('month') : moment().startOf('month').add(-6, 'month');
        const fecha_hasta = periodo ? moment(fecha_desde).endOf('month') : moment().endOf('month');
        dispatch({type: 'FILTERS', oppos: {filter: {responsable, fecha_desde, fecha_hasta, status: 'CERRADA', orden: 'valor'}}})
        dispatchTabs({type: 'gotoTabLabel', label: 'OPORTUNIDADES'})
    }

    const getVendedor = (id) => {
        const dd = vendedores.filter( it => it._id === id)
        return dd && dd[0] ? dd[0] : {}
    }

    const  formatCurrency = (num, r=1) => {
        if (!num)
            return '';
        if (num>1000 && num<1000000) {
            num = Number(num/1000);
            return num.toFixed(r) + "k";
        }
        if (num>=1000000) {
            num = Number(num/1000000);
            return num.toFixed(r) + "M";
        }
    }

    let sums6 = 0, cou6 = 0;
    Object.values(sumsVend).map(it => {sums6 += it})
    Object.values(countsVend).map(it => {cou6 += it})

    return (
        <Box {...other}>
            <TableContainer className="container" component={Paper}>
                <TableTitle title='Ventas por vendedor'/>
                <Table size='small' padding='none'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Vendedor</TableCell>
                            <TableCell align='center'>Distribuidor</TableCell>
                                { periodos.map(it => 
                                    <TableCell align='right'>{it}</TableCell>
                                ) }
                            <TableCell align='right'>TOT 6</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='total' align='center'>
                                TOTAL
                            </TableCell>
                            <TableCell className='total'></TableCell>
                            {periodos.map(it => (
                                <TableCell align='right' className='total'><a href="#" onClick={handleGoto(it)}>{formatCurrency(sumsPer[it])} / {counts[it]}</a></TableCell>
                            ))}
                            <TableCell align='right' className='total'>{formatCurrency(sums6)} / {cou6}</TableCell>
                        </TableRow>
                        {Object.keys(sales).map( ownerId => {
                            debugger
                            const row = sales[ownerId];
                            const tot = sumsVend[ownerId];
                            const cou = countsVend[ownerId];
                            const vend = getVendedor(ownerId);
                            const fCell = (valor, num) => {
                                return num && formatCurrency(valor) + " / " + num
                            }                
                            return (
                                <TableRow>
                                    <TableCell align='left'>{vend['username']}</TableCell>
                                    <TableCell align='center'>{vend['rep']}</TableCell>
                                    {row.map( (it, inx) => (
                                        <TableCell align='right'><a href="#" onClick={handleGoto(periodos[inx], vend['_id'])}>{fCell(it && it.valor, it && it.count)}</a></TableCell>
                                    ))}
                                    <TableCell align='right'><a href="#" onClick={handleGoto("", vend['_id'])}>{fCell(tot, cou)}</a></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const OpportunitySalesStyled = styled(OpportunitySales)(({ theme }) => ({
        "& .container": {
            m:2, height: '100%'
        },
        "& table th": {
            fontSize: '14px',
            fontWeight: 600,
            backgroundColor: 'beige',
            width: '20px'
        },
        "& table th.lastCols": {
            backgroundColor: '#9898e5'
        },
        "& table td": {
            fontSize: '12px',
            padding: '10px 5px'
        },
        ".total": {
            backgroundColor: '#DDD',
            fontWeight: 600
        }
    })
)

export default OpportunitySalesStyled;

export const OpportunitySalesStyledGrid = ({sizes={xs:6}, ...other}) => (
    <Grid sx={{overflow:'hidden'}} item {...sizes}>
        <OpportunitySalesStyled {...other}/>
    </Grid>
)
