import React, {useState, useEffect} from 'react'
import {SearchBox} from '../../../components'
import { getAPI } from '../../../utils' ;
import _ from 'underscore';

export const UserSearchBox = ({ comercial=false, vendedor=false, posventa=false, ...other}) => {
    const [lista, setLista] = useState([]);
    useEffect( () => {
        getAPI('/settings/users/')
        .then(data =>  {
            const usuarios = _.sortBy(data.users.filter(it => {
                if (!it.active) return false;
                if (vendedor) {
                    return it.roles.indexOf('esVendedor') > -1;
                }
                if (posventa) {
                    return it.roles.indexOf('esPosventa') > -1;
                }
                if (comercial) {
                    return it.roles.indexOf('esComercial') > -1;
                }
                return true;
            }), 'username')

            setLista([{label: 'TODOS', value: '-1'}].concat(usuarios.map(it => {return {label: it.username, value: it._id }})))
        })
        .catch(err => {
            console.error(err)
        });
    }, [])

    return <SearchBox sx={{minWidth: '120px', paddingRight: 2}} select options={lista} {...other}/>     
}