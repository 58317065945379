import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import {postAPI, activityLog} from '../../utils/';
import {ParseCSV} from '../../components';

import _ from 'underscore';

const POrderImportDialog = ({reference={}, open=true, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [progress, setProgress] = useState(false);

    const currentUser = useSelector(state => state.currentUser);

    const {doc: {tipo, detail}} = reference;
debugger
    const opts = tipo === 'MATERIALES' ? 
    {
      numColumns: 5,
      colsWithValues: [4]
    } : 
    {
      numColumns: 7,
      colsWithValues: [6]
    }

    const handleValidation = async (parsed) => {
        const data = parsed.data;
        const metaCol = [], errors = [], validation = {};

        const checkCodigo = (codigo) => {
          if (codigo[0] === "'") {
            return codigo.slice(1)
          }
          return codigo;
        }

        const unicos = {};
        const checkRow = (row, inx) => {
            if (row.length === 1 && !row[0]) return

            if (row.length !== opts.numColumns) {
                validation['col-num'] = validation['col-num'] || []
                validation['col-num'].push(inx + 1);
            }

            if ( !row[0] || !row[3] ) {
                validation['vacio'] = validation['vacio'] || [];
                validation['vacio'].push(inx + 1);
            }

            unicos[checkCodigo(row[0])] = unicos[checkCodigo(row[0])] || 0;
            unicos[checkCodigo(row[0])] += 1;

            if (unicos[checkCodigo(row[0])] > 1) {
                metaCol[inx] = {error: 'El código ya esta siendo subido en una linea anterior de la importación!'}
            }
        }

        if (data.length > 0) {

          data.forEach(checkRow);
          
          if (tipo === 'MATERIALES') {
            const artCodigos = data.map( it => checkCodigo(it[0]) );
            const articulos = await postAPI('/stock/parts/validate', {query: {activo:'SI', codigo: {$in: artCodigos}}, select: ['_id', 'codigo', 'deposito_udm']});
            const faltan = artCodigos.filter( it => !articulos.map(it1 => it1.codigo).includes(it) )
            
            data.forEach( (row, inx) => {

                const uno = articulos.filter(it1 => it1.codigo === checkCodigo(row[0]))
                if (uno && uno.length) {
                    const ff = detail.filter(it => it.articulo.codigo === checkCodigo(row[0]) && it.articulo_color === row[2]);
                    if (ff && ff.length) {
                      metaCol[inx] = {error: 'El art/color ya está utilizado en el pedido'}
                    } else {
                      row.push(uno[0]['deposito_udm'])
                      metaCol[inx] = {artId: uno[0]['_id']};  
                    }
                } else {
                    metaCol[inx] = {error: "Articulo inactivo/inexistente!"}
                }
            })
          }

          if (tipo === 'VIDRIOS') {
            debugger
            const artIds = {};
            data.map( row => {
              artIds[row[3]] = 1;
              artIds[row[4]] = 1;
              artIds[row[5]] = 1;
            });
            const vidrioArt = await postAPI('/stock/parts/validate', {query: {activo:'SI', codigo: 'VIDRIO'}, select: ['_id']});
            const articulos = await postAPI('/stock/parts/validate', {query: {activo:'SI', codigo: {$in: Object.keys(artIds)}}, select: ['_id', 'codigo', 'descripcion', 'deposito_udm']});
            data.forEach( (row, inx) => {

              const codigos = [row[3], row[4], row[5]];
              const c1 = articulos.filter(it1 => it1.codigo === codigos[0]);
              const cm = articulos.filter(it1 => it1.codigo === codigos[1]);
              const c2 = articulos.filter(it1 => it1.codigo === codigos[2]);

              if (vidrioArt.length === 0) {

                metaCol[inx] = {error: "VIDRIO generico inactivo/inexistente!"}

              } else {

                const ff = detail.filter(it => it.vidrio && it.vidrio.codigo === row[0]);
                if (ff && ff.length) {
                  metaCol[inx] = {error: 'El codigo ya está utilizado en el pedido'}
                }

                const errCaras = [];
                if (c1.length === 0 && codigos[0] || c1.length > 0 && !codigos[0]) {
                  errCaras.push(codigos[0])
                }
                if (cm.length === 0 && codigos[1] || cm.length > 0 && !codigos[1]) {
                  errCaras.push(codigos[1])
                }
                if (c2.length === 0 && codigos[2] || c2.length > 0 && !codigos[2]) {
                  errCaras.push(codigos[2])
                }
                if (errCaras.length) {
                  metaCol[inx] = {error: "Codigos de Caras inexistentes: " + errCaras.join(", ")}
                }
                if (row[1] === 0) {
                  metaCol[inx] = {error: "Ancho debe ser mayor a cero"}
                }
                if (row[2] === 0) {
                  metaCol[inx] = {error: "Alto debe ser mayor a cero"}
                }
              }
              if (!metaCol[inx]) {
                const vidrios = {
                  codigo: row[0],
                  ancho: row[1].replace(",", "."),
                  alto: row[2].replace(",", "."),
                };
                if (c1 && c1.length) {
                  vidrios['c1'] = row[3];
                  vidrios['c1Id'] = c1[0]._id;
                  vidrios['c1_desc'] = c1[0].descripcion;
                }
                if (c2 && c2.length) {
                  vidrios['c2'] = row[5];
                  vidrios['c2Id'] = c2[0]._id;
                  vidrios['c2_desc'] = c2[0].descripcion;
                }
                if (cm && cm.length) {
                  vidrios['cm'] = row[4];
                  vidrios['cmId'] = cm[0]._id;
                  vidrios['cm_desc'] = cm[0].descripcion;
                }
                row.push(vidrioArt[0]['deposito_udm'])
                metaCol[inx] = {artId: vidrioArt[0]['_id'], vidrios};
              }
            })
          }

          if (validation['col-num']) {
              errors.push( {
                  tipo: 'col-num',
                  rows: validation['col-num'],
                  msg: tipo === 'MATERIALES' ? 
                    "Las filas " + validation['col-num'].join(', ') + " deben tener 5 columnas: CODIGO, DESCRIPCION, COLOR, CANTIDAD, UDM" :
                    "Las filas " + validation['col-num'].join(', ') + " deben tener 8 columnas: TIPO, ANCHO, ALTO, CARA1, CARA2,CARA3, CANTIDAD"
                });
          }
          if (validation['vacio']) {
              errors.push({
                  tipo: 'vacio',
                  rows: validation['vacio'],
                  msg: "Las filas " + validation['vacio'].join(', ') + " deben tener las columnas: CODIGO, DESCRIPCION, COLOR, CANTIDAD, UDM con valores"
              });
          }
        }
        return {metaCol, errors};
    }

    const handleProcessCSV = (parsed, metaCol, onProgress, cb) => {
        const data = parsed.data;
        if (!data) {
            enqueueSnackbar('No se parsea datos', {variant: 'error'});
            return cb(true, [])
        }

        setProgress(true)

        const {docId, doc: {tipo, np, obra, of, provee}} = reference;

        // convierte array a hash
        const list = data.map( (row, inx) => {
            const linea = {
                articulo:       {_id: metaCol[inx]['artId']},
                articulo_color: tipo !== 'VIDRIOS' ? row[2] : "",
                pedido:         tipo === 'VIDRIOS' ? row[6] : row[3],
                udm:            tipo !== 'VIDRIOS' ? row[4] : 'UN',
                obra,
                of,
                provee,
            }
            if (tipo === 'VIDRIOS') {
              linea['vidrio'] = metaCol[inx]['vidrios']
            }
            return linea
        }).filter((row, inx) => !metaCol[inx]['error'])
        debugger
        postAPI('/stock/porders/' + docId + '/postList', {list})
        .then(res => {
            setProgress(false);

            const msg = "Se importó una nueva lista de " + list.length + " artículos.";
            activityLog({label: "NP-" + np, app:'compras', docId, msg, currentUser})

            enqueueSnackbar(msg, {variant: 'success'});

            if (cb(null, res) && onClose) {
                onClose()
            }
        })
        .catch(err => {
          setProgress(false);
          enqueueSnackbar(err.message, {variant: 'error'});
          cb(err, null)
        })
    }

    const transformCSV = (value, colNum) => {
      //debugger
      let out = value;
      if (_.isString(value)) {
        out = value.trim();
        if ( [opts['numColumns']].indexOf(colNum) > -1 ) {
          out = out.replace(',','.')
        }
      }
      return out
    }

    return (
      <Dialog
        disableBackdropClick
        //disableEscapeKeyDown
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={onClose}
      >
          <DialogTitle>Importación de Lineas de Pedido {tipo}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Puede preparar un archivo de texto en una planilla de calculo con {opts['numColumns']} columnas 
              {
                tipo === 'MATERIALES' ? 
                "(código artículo, descripción, color, pedido, unidad)." :
                "(código vidrio, ancho, alto, cara1, cara2, cara3, cantidad)."
              }  
              Se va a borrar la lista anterior.
            </DialogContentText>
            { progress &&
              <div>
                <LinearProgress/>
                <Typography style={{textAlign:'center'}}>Espere mientras se termine este proceso</Typography>
              </div>
            }

            <ParseCSV
              open={true}
              withTitle={false}
              //withHeader={true}
              skipEmptyLines
              delimiter={"\t"} // es importante los curly!!!
              dynamicTyping={false}
              transform={transformCSV}
              onValidation={handleValidation}
              onProcess={handleProcessCSV}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disable={!progress} color="primary">
              Cancelar
            </Button>
          </DialogActions>
      </Dialog>
    )
}


export default POrderImportDialog;
