import React, {useEffect, useState} from 'react'
import { useSnackbar } from 'notistack';
// import { useSelector } from "react-redux";
// import { styled } from '@mui/material/styles';

import LinearProgress from '@mui/material/LinearProgress';
import { HeaderBar, PrintPreview, Table } from '../../components';

import {getAPI} from '../../utils';
import moment from 'moment'


const columns = (modo, valores, errores, handleValores, handleInventory) => ([
    {
        name: 'Proveedor',
        field: v => v.provee && `[${v.provee.codigo_proveedor}] ${v.provee.fullname}`,
        width: 90,
    },
    {
        name: 'Tipo',
        field: v => v.tipo,
    },
    {
        name: 'Precio',
        field: v => v.precio,
    },
    {
        name: 'Moneda',
        field: v => v.moneda,
        width: 150,
    },
    {
        name: 'Vigencia Desde',
        field: v => moment(v.vigencia_desde).utc().format("YYYY-MM-DD"),
        width: 150,
    },
    {
        name: 'Vigencia Hasta',
        field: v => moment(v.vigencia_hasta).utc().format("YYYY-MM-DD"),
        width: 150,
    },
])

const PartListPrices = ({docId}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect( () => {
        if (!docId) return 
        setLoading(true);
        getAPI('/stock/parts/' + docId + '/priceLists')
        .then(data => {
            debugger
            data && data.list.forEach( it => {
                it.id = it._id;
            });

            if (data.list) setData(data.list)
            setLoading(false);
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
            setLoading(false);
          });   
  
    }, [docId, enqueueSnackbar])

    return (
        <>
            {loading && <LinearProgress/>}

            <Table 
                stickyHeader
                loading={loading}
                cols={columns()}
                data={data}

                // pagination
                // page={page}
                // rowsPerPage={perPage}
                // rowCount={num}
                // rowsPerPageOptions={[50,100,300]}
                // onPageChange={(ev, newPage) => setPage(newPage)}
                // onRowsPerPageChange={ev => setPerPage(ev.target.value)}

                // extraPagination={extraPagination()}

                tableProps={{
                    size:'small', padding: 'none', stickyHeader: true
                }}
                headerProps={{
                    sx: { 
                        th: {
                            fontSize: 13, 
                            fontWeight:'bold', 
                            color:'white', 
                            backgroundColor: "#549857", //green[500], 
                            textAlign: 'center', 
                            borderRight: '1px solid #fff',
                            p: '2px 5px'
                        }
                    }
                }}
                rowProps={{
                    sx: {
                        m:1, 'td': {fontSize: '12px', p: '0px 10px'}}
                }}
            />

        </>

    )
}

export default PartListPrices;