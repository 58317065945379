import React, {useState, useEffect} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox} from '../../../components'; 
import ExchangeEditModal from './ExchangeEditModal';
import {getAPI, deleteAPI} from '../../../utils' ;
import moment from 'moment';

const columns = (onEdit, onDelete) => ([
    {
        field: 'fecha',
        headerName: 'Fecha',
        //type: 'date',
        valueGetter: (params) => {
            //return params.value && moment(params.value).utc().format("YYYY-MM-DD");

            return params.row && params.row.fecha && moment(params.row.fecha).utc().format("YYYY-MM-DD");
            
            // OBS este genera recursividad 
            //const fecha = params.value;
            //return fecha && moment(fecha).utc().format("YYYY-MM-DD")
        },
        sortable: false,
        width: 150,
    },
    {
        field: 'USDARG',
        headerName: 'Usd/Arg',
        sortable: false,
        width: 120,
    },
    {
        field: 'EURARG',
        headerName: 'Eur/Arg',
        type: 'string',
        width: 120,
    },
    {
      field: 'EURUSD',
      headerName: 'Eur/Usd',
      type: 'string',
      width: 120,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = () => {
    debugger
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [editDocId, setEditDocId] = useState("");
    const [reload, setReload]   = useState(false);

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    useEffect( () => {
        debugger
        setReload(false)
        setLoading(true)
        getAPI('/settings/exchanges/', {filter, skip: page*limit, limit})
        .then(data => {
            setLoading(false);
            data && data.list.forEach( it => it.id = it._id);
            setRows(data && data.list)
            setNum(data && data.num)
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });

      }, [filter, page, reload, enqueueSnackbar])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleEdit = (item) => (ev) => {
        setEditDocId(item.row._id)
    }
    const handleNew = (ev) => {
        setEditDocId("new")
    }
    const handleClose = (obj) => {
        if (obj && obj.res) {
            setReload(true)
        }
        setEditDocId("")
    }
    const handleDelete = (item) => (ev) => {
        debugger
        setLoading(true)
        deleteAPI('/settings/exchanges/' + item.row._id)
        .then(data => {
            setLoading(false);
            setReload(true);
            enqueueSnackbar("Se borro el registro correctamente", {variant: "info"})
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.msg, {variant: "error"})
        });
    }

    return (
        <PageBase title={
                    <>
                        <PageTitle>Tipos de Cambio ({num})</PageTitle> 
                        <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                    </>
                }
                actions={
                    <>
                        <Button sx={{m:1}} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button>
                    </>
                }
                    
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(handleEdit, handleDelete)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

            { editDocId && <ExchangeEditModal editDocId={editDocId} onClose={handleClose}/> }    

        </PageBase>
    )
}

export default IndexPage;

// {
//   field: 'fecha',
//   headerName: 'Usd/Arg',
//   valueGetter: (params) => {
//     const fecha = params.value;
//     return fecha && moment(fecha).utc().format("YYYY-MM-DD")
//   },
//   sortable: false,
//   width: 120,
// },
// {
//   field: 'usdarg',
//   headerName: 'Usd/Arg',
//   sortable: false,
//   width: 120,
// },
// {
//   field: 'eurarg',
//   headerName: 'Eur/Arg',
//   type: 'string',
//   width: 200,
// },
// {
// field: 'eurusd',
// headerName: 'Usd/Eur',
// type: 'string',
// width: 200,
// },
// {
//   field: 'actions',
//   headerName: 'Acciones',
//   type:'actions',
//   getActions: (params) => [
//     <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
//     <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
//   ]
// }
