import React, {useEffect, useState, useContext} from 'react';
import { TabsContext } from '../Layout/TabsProvider'; 
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableTitle } from '../../components';

import {getAPI} from '../../utils' ;


const OpportunitySalesmen = ({reload, ...other}) => {
    const [loading, setLoading] = useState(false);
    const [stat, setStat] = useState([])
    const {dispatchTabs} = useContext(TabsContext);
    const dispatch = useDispatch();

    useEffect( () => {
        setLoading(true)
        getAPI('/crm/oppos/statsVend', {})
        .then(data => {
            debugger
            setLoading(false);
            setStat(data['vendOppos'].sort((a,b) => {
                let a1 = (a[2]||"") + a[1];
                let b1 = (b[2]||"") + b[1];
                if (a1 < b1) { return 1}
                if (a1 > b1) { return -1}
                return 0
            }) )
        })
        .catch(err => {
            console.error(err)
        });
    }, [reload])

    const handleGoto = (responsable, status, revision) => ev => {
        dispatch({type: 'FILTERS', oppos: {filter: {responsable, status, revision, orden: 'valor'}}})
        dispatchTabs({type: 'gotoTabLabel', label: 'OPORTUNIDADES'})
    }

    return (
        <Box {...other}>
            <TableContainer className="container" component={Paper}>
                <TableTitle title='Oportunidades por vendedor'/>
                <Table size='small' padding='none'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Vendedor</TableCell>
                            <TableCell align='center'>Distribuidor</TableCell>
                            <TableCell align='center'>Ing</TableCell>
                            <TableCell align='center'>Pres</TableCell>
                            <TableCell align='center'>Apro</TableCell>
                            <TableCell align='center'>Trat</TableCell>
                            <TableCell align='center'>Gan</TableCell>
                            <TableCell align='center'>Per</TableCell>
                            <TableCell align='center'>Cer</TableCell>
                            <TableCell className='lastCols' align='center'>SinF</TableCell>
                            <TableCell className='lastCols' align='center'>RevN</TableCell>
                            <TableCell className='lastCols' align='center'>Rev7C</TableCell>
                            <TableCell className='lastCols' align='center'>Rev30</TableCell>
                            <TableCell className='lastCols' align='center'>Rev30+</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stat.map( it => { return it && it[0] && it[3] && Object.keys(it[3]).length > 0 && 
                            <TableRow>
                                <TableCell><a href="#" onClick={handleGoto(it[0], 'TODOS', '-1')}>{it[1]}</a></TableCell>
                                <TableCell align='center'>{it[2]}</TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'INGRESADA', "-1")}>{it[3]['numI']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'A PRESUPUESTAR', "-1")}>{it[3]['numPR']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'PARA APROBAR', "-1")}>{it[3]['numAP']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'EN TRATATIVA', "-1")}>{it[3]['numT']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'GANADA', "-1")}>{it[3]['numG']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'PERDIDA', "-1")}>{it[3]['numP']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'CERRADA', "-1")}>{it[3]['numC']}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'TODOS', '-2')}>{it[4]}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'EN TRATATIVA', "0")}>{it[5]}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'EN TRATATIVA', "1")}>{it[6]}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'EN TRATATIVA', "2")}>{it[7]}</a></TableCell>
                                <TableCell align='center'><a href="#" onClick={handleGoto(it[0], 'EN TRATATIVA', "3")}>{it[8]}</a></TableCell>
                            </TableRow>
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )

}

const OpportunitySalesmenStyled = styled(OpportunitySalesmen)(({ theme }) => ({
        "& .container": {
            m:2, height: '100%'
        },
        "& table th": {
            fontSize: '14px',
            fontWeight: 600,
            backgroundColor: 'beige',
            width: '20px'
        },
        "& table th.lastCols": {
            backgroundColor: '#9898e5'
        },
        "& table td": {
            fontSize: '12px',
            padding: '10px 5px'
        },
    })
)

export default OpportunitySalesmenStyled;

export const OpportunitySalesmenGrid = ({sizes={xs:6}, ...other}) => (
    <Grid sx={{overflow:'hidden'}} item {...sizes}>
        <OpportunitySalesmen {...other}/>
    </Grid>
)
