import React, {forwardRef, useState, useEffect} from 'react'; 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import {SelectField} from '../../components'; 
import {getAPI} from '../../utils' ;
import _ from 'underscore'

const AlmacenField = forwardRef( ({ partId="", partCol="", value, ubi1="", ubi2="", ubi3="", exist, reserved, onChange, disabled=false, size='small', ...other}, ref) => {
    const [options, setOptions] = useState([]);

    useEffect( () => {
        if (partId) {
            debugger
            getAPI('/stock/inventory', {articuloId:partId, articuloColor:partCol, level: 'ubi'})
            .then(data => {
                const opts = data.list.map( it => ({
                    label: [it.almacen.codigo, it.ubi1, it.ubi2, it.ubi3, it.exist, it.reserved ].join(' | '), 
                    value: it.almacen._id + "|" + it.ubi1 + "|" + it.ubi2 + "|" + it.ubi3,
                    almacen: {_id: it.almacen._id}, 
                    ubi1: it.ubi1, 
                    ubi2: it.ubi2, 
                    ubi3: it.ubi3, 
                    exist: it.exist, 
                    reserved: it.reserved
                }))
                setOptions(opts)
                if (onChange) {
                    // simula un evento y manda extra data
                    opts && onChange('almacen')({target: {value: opts[0].value}}, {props:{"data-other":opts[0]}});
                }
            })
            .catch(err => {
                console.error(err)
            });    
        } else {
            getAPI('/settings/warehouses')
            .then(data => {
                const opts = _.sortBy(data.list, it => it.planta+it.codigo).map( it => ({label: it.planta + "-" + it.codigo, value: it._id }))
                setOptions(opts);
            })
        }
    }, [partId, partCol, onChange])

    // const options_alm = _.sortBy(options, it => it.planta+it.label).map( it => ({label: it.planta + "-" + it.codigo, value: it._id, exist: it.exist}))

    const renderValue = (value) => {
        const sel = options.filter( it => it.value  === value)
        if (sel.length) {
            const sel1 = sel[0];

            return sel1.label
        } else {
            return ""
        }
    }
    return (
        <>
        <SelectField 
            label='Almacen' 
            options={options} 
            value={value} 
            disabled={disabled} 
            size={size} 
            onChange={onChange('almacen')} 
            SelectProps={{renderValue}} 
            {...other} 
        />

        <Box sx={{display: 'inline'}}>
            <TextField sx={{ml:1, width: '80px'}} disabled={disabled} size={size} label='Ubi1' value={ubi1} onChange={!partId && onChange('ubi1')}/> 
            <TextField sx={{ml:1, width: '80px'}} disabled={disabled} size={size} label='Ubi2' value={ubi2} onChange={!partId && onChange('ubi2')}/> 
            <TextField sx={{ml:1, width: '80px'}} disabled={disabled} size={size} label='Ubi3' value={ubi3} onChange={!partId && onChange('ubi3')}/>
            {!disabled && partId !== "" && 
                <>
                <Box sx={{ml:1, display: 'inline'}}>Stock={exist || "SIN STOCK"}</Box>
                <Box sx={{ml:1, display: 'inline'}}>Reservas={reserved || "SIN"}</Box>
                </>
            } 
        </Box>

        </>
    )
})

export default AlmacenField;