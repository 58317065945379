import React, {forwardRef, useEffect, useState, useContext} from 'react'; 
import { useSnackbar } from 'notistack';

import {GridActionsCellItem} from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import {DataGrid} from '../../components'; 
import {getAPI} from '../../utils';

import { ParamsContext } from './NotificationIndex';

import moment from 'moment';

const columns = (onClick=()=>{}, onEdit=()=>{}) => ([
    {
      field: 'tasklist.boardlist',
      headerName: 'Tablero',
      type: 'String',
      renderCell: params => {
        const name = params.row && params.row.tasklist && params.row.tasklist.boardlist && params.row.tasklist.boardlist.name || "";
        if (name) {
          const link = (
            <IconButton size='small' onClick={onClick('TASK')({id: params.row.tasklist.boardlist._id, board: params.row.tasklist.boardlist})}>
                <ExitIcon fontSize='10'/>
            </IconButton>
          )
          return <Box>{name} {link}</Box> 
        }
        return ""
      },
      width: 140,
    },
    {
      field: 'tasklist.name',
      headerName: 'Categoria',
      type: 'String',
      valueGetter: params => params.row && params.row.tasklist && params.row.tasklist.name || "",
      width: 150,
    },
    {
      field: 'asunto',
      headerName: 'Asunto',
      type: 'String',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 120,
    },

    {
      field: "tags",
      headerName: 'Referencias',
      renderCell: (params) => {
        const out = []
        const row = params.row;
        if (row.obra) {
          const tag = <Chip size='small' onClick={onClick('OBRA')(row.obra)} label={"OB-" + row.obra.carpeta + " " + row.obra.nombre}/>
          out.push( tag )
        }
        if (row.pv) {
          const tag = <Chip size='small' onClick={onClick('PVTA')(row.pv)} label={"PV-" + row.pv.pv + " " + row.pv.nombre}/>
          out.push( tag )
        }
        if (row.of) {
          const tipo = row.of.tipo === 'fabrica' ? "OF-" : "OS-";
          const tag = <Chip size='small' onClick={onClick('WORK-F')(row.of)} label={tipo + row.of.of}/>
          out.push( tag )
        }
        if (row.np) {
          const tag = <Chip size='small' onClick={onClick('NP')(row.np)} label={"NP-" + row.np.np}/>
          out.push( tag )
        }
        return <Box sx={{overflow: 'hidden'}}>{out}</Box>
      },
      flex: 1,
    },
    {
      field: 'ownerId',
      headerName: 'Responsable',
      //type: 'date',
      valueGetter: params => params.value && params.value.username,
      width: 110
    },
    {
      field: 'updated_at',
      headerName: 'Actualizado',
      //type: 'date',
      valueGetter: params => moment(params.value).utc().format('YYYY-MM-DD'),
      width: 110
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type:'actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params.row)} label="Edit" />,
      ]
    }
  ])

const ListPage = forwardRef( ({
    query, 
    limit=100, 
    onClick=()=>{},
    onEdit=()=>{},
}, ref) => {

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const {params={}, dispatchParams=()=>{}} = useContext(ParamsContext);

    const sort = 'updated_at:-1';

    const { enqueueSnackbar } = useSnackbar();

    useEffect( () => {
        dispatchParams({type: 'setReload', reload: false})
        setLoading(true)
        getAPI('/task', {...query, sort, skip: page*limit, limit})
        .then(data => {
            debugger
            setLoading(false);
            data && data.list.forEach( it => it.id = it._id);
            setNum(data.num)
            setRows(data.list)
            dispatchParams({type: 'setNum', num: data.num});
          })
        .catch(err => {
            console.error(err)
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }, [params.reload, query, page, limit, dispatchParams, enqueueSnackbar])

    const handlePageChange = (newPage) => setPage(newPage)

    const columnVisibilityModel = {
      // 'tasklist.boardlist': boardId ? false : true  
      // 'ofs_rel': tipo === 'WORK-S',
      // 'of_rel': tipo === 'WORK-F'
    }
    
    return (
      <DataGrid
          loading={loading} 
          rows={rows} 
          rowCount={num}
          columns={columns(onClick, onEdit)} 
          disableColumnFilter
          sortingOrder='null'
          columnVisibilityModel={columnVisibilityModel}

          pageSize={limit}
          onPageChange={handlePageChange}

          rowHeight={35} 
          hideFooter={false}              
      />
    )
})

export default ListPage;