import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSelector, useDispatch } from "react-redux";

//import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {PageBase, PageTitle, SearchBox, ExportButton, ResetButton} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce} from '../../utils' ;
import ProjectList from './ProjectList';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {UserSearchBox} from '../Settings/Users/UserSearchBox';

import {options_project_status, EXCEL_LIMIT} from "../../appConfig.js"

import moment from 'moment';

const optionsOrder = [
    {label: 'Obra', value: 'obra'},
    {label: 'Nombre', value: 'nombre'},
    {label: 'Creado', value: 'created_at'},
    {label: 'Actualizado', value: 'updated_at'},
]

const queryOptions = [
    {label: 'TODOS', value:"-1"},
    {label: 'Obras sin planning', value: 'noPlanning'},
]

const defaultFilter = {filter: "", consulta: "-1", status: "-1", order: 'obra', ownerId: "-1", distribuidor: "-1"}
const IndexPage = forwardRef( ({isTabSelected}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [filter, setFilter] = useState(defaultFilter);
    const [reload, setReload] = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    let filtrosExt = useSelector(state => state.projects);

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit'])

    const optionsStatus = [{label: 'TODOS', value:'-1'}].concat(options_project_status)
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];
    
    useEffect( () => {
        if (isTabSelected === true) {
            //setReload(true)
        }
    }, [isTabSelected]);

    useEffect( () => {
        debugger
        const aa = canOpts.filter( it => !!it.default );
        if (aa.length) {
            setFilter(v => ({...v, distribuidor: aa[0]._id}))
        } else {
            setFilter(v => ({...v, distribuidor: canOpts[0]._id}))
        }
    }, [canOpts])

    const fn = useCallback( 
        debounce(
            (filter, page, reload, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                getAPI('/project/', {...filter, skip: page*limit, limit, sort: 'updated_at:-1'})
                .then(data => {
                    setLoading(false);
                    debugger
                    if (data && data.list) {
                        data.list.forEach( it => it.id = it._id);
                        setNum(data.num)
                        setRows(data.list)    
                    }
                  })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });        
            }, 500),
        []
    )
    
    useEffect( () => {
        fn(filter, page, reload, enqueueSnackbar)
    }, [fn, filter, page, reload, enqueueSnackbar])

    useEffect( () => {
        setPage(0)
        setFilter({...defaultFilter, ...filtrosExt.filter})
    }, [filtrosExt.filter])

    const handleSearch = fld => (ev) => {
        debugger
        const valor = ev.target.value;
        setFilter(v => ({...v, [fld]: valor}))
    }
    const handleClick= tipo => item => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item)
    }
    const handleClear = (ev) => {
        setFilter(defaultFilter)
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Obras`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Obra', field: 'carpeta'},
                    {title: 'Nombre', field: 'nombre'},
                    {title: 'Status', field: 'status'},
                    {title: 'Distribuidor', field: '_', transform: (v, doc) => doc.distributorId && doc.distributorId.codigo},
                    {title: 'Planning', field: '_', transform: (v, doc) => doc.tiene_planning ? 'SI' : 'NO'},
                    {title: 'Dias de Ingreso', field: '_', transform: (v, doc) => {
                        const fecha = doc.created_at;
                        return fecha && moment(fecha).fromNow('days')    
                    }},
                    {title: 'Cliente', field: '_', transform: (v, doc) => {
                        if (doc.cliente) {
                            return doc.cliente.fullname;
                        }
                    }},
                    {title: 'Tareas', field: 'tasksNum'},
                    {title: 'Direccion', field: 'direccion'},
                    {title: 'Marca', field: 'marca'},
                    {title: 'Color', field: 'color'},
                    {title: 'Presupuesto', field: 'presupuesto'},
                    {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                    {title: 'Barrio', field: 'barrio'},
                    {title: 'Localidad', field: 'localidad'},
                    {title: 'Creado', field: 'created_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    // {title: 'id', field: '_id'}
            ],
            styles: {}
        }
    
        const rows = await getAPI('/project/', {...filter, skip: page*limit, limit: 3*EXCEL_LIMIT, sort: 'updated_at:-1'})

        reportConfig['rows'] = rows.list;
    
        return reportConfig;
      }, [filter]);

    const columnVisibilityModel={
        actions: !!esLocalAdmin
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <>
                    <Hidden smDown>
                        <PageTitle>Obras ({num})</PageTitle>
                    </Hidden>
                </>
            }
            toolbar={
                <Box>
                    <SearchBox sx={{ml:1, mr:2, flexGrow:1}} label="Carpeta/Nombre/Presu" value={filter['filter']} onChange={handleSearch('filter')}/>
                    <SearchBox label="Status" select multiple options={optionsStatus} value={filter['status']} onChange={handleSearch('status')}/>
                    <Hidden lgDown='2'>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidores" select options={optionsDistrib} value={filter['distribuidor']} onChange={handleSearch('distribuidor')}/>
                        <UserSearchBox label="Vendedor" vendedor value={filter['ownerId']} onChange={handleSearch('ownerId')}/>
                    </Hidden>
                    <SearchBox label="Consultas" select options={queryOptions} value={filter['consulta']} onChange={handleSearch('consulta')}/>
                    <SearchBox label="Ordenado" select options={optionsOrder} value={filter['order']} onChange={handleSearch('order')}/>
                    <ResetButton onClick={handleClear}/>
                </Box>
            }
            actions={
                <>
                    { esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('OBRA')({id: true, _id: 'new'})}>Nuevo</Button> }
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app='obras' withLabel/>
                </>
            }   
       >           
            <ProjectList
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                columnVisibilityModel={columnVisibilityModel}

                onClick={handleClick}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;