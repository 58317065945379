import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import TablePagination from '@mui/material/TablePagination';
import ReorderIcon from '@mui/icons-material/Reorder';

import { useDrag, useDrop } from 'react-dnd';

const DRAG_TYPE = 'ROW';

const MyTableRow = ({key, inx, dataRow, cols, moveRow, rowProps={}, withDrag=false, isLastRow=true, odd = false}) => {
    // Drag-and-drop hooks
    const [{ isDragging }, drag, dragRef] = useDrag({
        type: DRAG_TYPE,
        item: { index: inx }, // Define the dragged item here.
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(), // Collect dragging state
        }),
    });

    const [, dropRef] = useDrop({
        accept: DRAG_TYPE,
        hover: (draggedItem, monitor) => {
            console.log(`Dragging index: ${draggedItem.index}, Hovering over index: ${inx}`);
        },
        drop: (draggedItem, monitor) => {
            console.log(`Dropped index: ${draggedItem.index}, Hovering over index: ${inx}`);
            if (draggedItem.index !== inx) {
                moveRow(draggedItem.index, inx); // Trigger row reordering
                draggedItem.index = inx; // Update dragged item's index
            }
        }
    });

    // Apply style when dragging
    const dragStyle = isDragging ? {
              opacity: 0.5, // Reduce opacity
              backgroundColor: '#f0f0f0', // Highlight row
          }
        : {};

    if (!cols) return null;
    let {sx={}, className, ...other} = rowProps;
    if (!isLastRow) {
        sx['& td'] = {borderBottom: 0}
    }

    if (odd === true) {
        // sx['backgroundColor'] = '#EEE'
    }

    return (
        <TableRow 
            ref={(node) => dragRef(dropRef(node))} // Combine drag and drop refs
            
            key={key || dataRow.key || dataRow._id || `row-${inx}`} 
            sx={{...sx, ...dragStyle }}
            {...other}
        >
            {withDrag && <div ref={drag}><ReorderIcon sx={{mt:'10px', color: '#666'}}/></div>}
            {cols.map( col => !col.hide && 
                                <TableCell {...col.td}>
                                    {col.field(dataRow, inx)}
                                </TableCell>
            )}
        </TableRow> 
    )
}


const TableMUI = ({
    sx={}, cols=[], 
    data=[], 
    tableProps={}, 
    headerProps={}, 
    rowProps={}, 
    loading=false, 
    colsSelect,
    colsExtra=false,
    extraPagination='',
    withDrag=false,
    dragSelect,
    pagination:paginationProp=false,
    page=0,
    onPageChange=()=>{},
    rowCount=0,
    rowsPerPage=25,
    rowsPerPageOptions=[10,25,50,100],
    onRowsPerPageChange=()=>{},
    onDrop=()=>{}
}) => {
    // Local state to manage table data
    const [tableData, setTableData] = useState(data);

    // Handle row reordering
    const moveRow = (fromIndex, toIndex) => {
        if (fromIndex && toIndex) {
            debugger
            const updatedData = [...tableData];
            const [movedItem] = updatedData.splice(fromIndex, 1);
            updatedData.splice(toIndex, 0, movedItem);
            setTableData(updatedData); // Update table state    
            if (onDrop) {
                onDrop(movedItem, fromIndex, toIndex)
            }
        }
    };

    useEffect(() => {
        setTableData(data); // Update tableData if the original data changes
    }, [data]);

    return (

        <Box sx={{height: paginationProp ? 'calc(100% - 100px)' : '100%'}}>

            {loading && <LinearProgress/>}
            <TableContainer sx={{height: '100%', ...sx}} component={Paper}>
                <Table size='small' padding='normal' stickyHeader {...tableProps}>
                    <TableHead>
                        <TableRow {...headerProps}>
                            {withDrag && <TableCell key={"drag-1"}></TableCell>}
                            { cols.map( col => !col.hide && <TableCell {...col.th}>{col.name}</TableCell>) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {   tableData.map( (dataRow, inx) => {
                                const cc = colsSelect ? colsSelect(dataRow, inx) : cols;
                                const dd = dragSelect ? dragSelect(dataRow, inx) : withDrag;
                                const extra = colsExtra && colsExtra(dataRow, inx);
                                const odd = inx % 2 === 0;
                                let out = [
                                    <MyTableRow 
                                        dataRow={dataRow} 
                                        inx={inx} 
                                        cols={cc} 
                                        withDrag={dd}
                                        moveRow={moveRow} // Pass the moveRow function
                                        rowProps={rowProps}
                                        isLastRow={!extra}
                                        odd={odd}
                                    />                                    
                                ]
                                if (extra) {
                                    out.push(
                                        <MyTableRow 
                                            key={'H'+(dataRow.key||dataRow._id)} 
                                            dataRow={dataRow} 
                                            withDrag={withDrag}
                                            inx={inx} 
                                            cols={colsExtra(dataRow, inx)} 
                                            rowProps={rowProps}
                                            odd={odd}
                                        />
                                    )
                                }
                                return out;
                            })
                        }
                        { data.length === 0 && 
                            <TableRow key='nodata'>
                                <TableCell colSpan={cols.length} sx={{textAlign:'center'}}>No hay data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>

            </TableContainer>

            {extraPagination}

            {
                paginationProp && 
                <TablePagination 
                    sx={{float: 'right'}} 
                    count={rowCount} 
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage="Filas por página"
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            }

        </Box>
    )
}

export default TableMUI;