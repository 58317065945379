import React, {useEffect, useState, useContext} from 'react';
import { TabsContext } from '../Layout/TabsProvider'; 
import { useDispatch } from "react-redux";
import { blue, yellow } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import {DashboardBase, NumberCard} from '../../components';
import {getAPI} from '../../utils' 
import OpportunitySalesmen  from './OpportunitySalesmen';
import OpportunitySales from './OpportunitySales';

import moment from 'moment';

const NumberCardGrid = ({sizes={xs:6}, ...other}) => (
    <Grid item {...sizes}>
        <NumberCard {...other}/>
    </Grid>
)

const DashPage = ({tabKey}) => {
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [stat, setStat] = useState({
        oppos:{
            en_tratativa:0,
            a_presupuestar:0,
            ganadas_ult_7:0
        },
        obras: {
            sin_planilla: 0
        }
    })
    
    const {curTab, dispatchTabs} = useContext(TabsContext);
    const dispatch = useDispatch();

    useEffect( () => {
        debugger
        if (curTab.key === tabKey) {
            console.log(curTab)
            setReload(true)
        }
    }, [curTab, tabKey])

    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/crm/oppos/stats')
        .then(data => {
            setLoading(false);
            setStat(data)
        })
        .catch(err => {
            console.error(err)
        });

    }, [reload])

    const handleClick = (tipo, filter) => () => {
        if (tipo === 'OPORTUNIDADES') {
            dispatch({type: 'FILTERS', oppos: {filter}})
        }
        if (tipo === 'OBRAS') {
            dispatch({type: 'FILTERS', projects: {filter}})
        }
        dispatchTabs({type: 'gotoTabLabel', label: tipo})
    }

    const fecha_desde = moment().utc().startOf('day').add(-1, 'month');
    const fecha_hasta = moment().utc().startOf('day');

    const items = [
        {
            component: <NumberCard title="OPOS" label="En Tratativa" value={stat.oppos.en_tratativa} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA', orden: 'valor'})}/>,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="A Presupuestar" value={stat.oppos.a_presupuestar} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'A PRESUPUESTAR', orden: 'valor'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="Ganadas" value={stat.oppos.ganadas} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'GANADA', orden: 'valor'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="Cerradas Ult Mes" value={stat.oppos.cerradas_ult_mes} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'CERRADA', orden: 'valor', fecha_desde, fecha_hasta})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OBRAS" label="Sin Planilla" value={stat.obras.sin_planilla} color={yellow} onClick={handleClick('OBRAS', {status: 'SP'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OBRAS" label="C90" value={stat.obras.c90} color={yellow} onClick={handleClick('OBRAS', {status: 'C90'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <OpportunitySalesmen reload={reload} />,
            sizes: {xs:6}
        },
        {
            component: <OpportunitySales reload={reload} />,
            sizes: {xs: 6}
        }

    ]
        
    return (
        <DashboardBase 
            items={items}
            loading={loading}
        />
    )
}

export default DashPage;