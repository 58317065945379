import React, {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import CounterForm from './CounterForm';
import {PageBase, PageTitle, DataGrid} from '../../../components';

import {getAPI } from '../../../utils';

const columns = (onEdit) => ([
    {
        field: '_id',
        headerName: 'Nombre',
        type: 'string',
        width: 100,
    },
    {
        field: 'seq',
        headerName: 'Valor',
        type: 'number'
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
        ]
      }    
])

const CounterIndex = () => {
    const [data, setData] = useState([])
    const [edit, setEdit] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    useEffect( () => {
        setLoading(true);
        setReload(false)

        getAPI('/settings/counters')
        .then(data => {
            setLoading(false);
            data && data.list.forEach( it => it.id = it._id);
            setData(data.list)
        })
        .catch(err => {
            console.error(err)
        });

    }, [reload])

    const handleEdit = (params) => (ev) => {
        setEdit(params.row)
    }
    const handleClose = () => {
        setEdit(undefined)
        setReload(true)
    }
    return (
        <PageBase 
            title={<PageTitle>Contadores generales</PageTitle>}
            actions={
                <>
                    <Button sx={{m: 1 }} size='small' variant='outlined' onClick={handleEdit({row: {_id: 'new'}})}>Nuevo</Button>
                </>
            }
        >

            {
                !edit && <DataGrid
                            loading={loading} 
                            rows={data} 
                            rowCount={data.length}
                            columns={columns(handleEdit)} 
                        />
            }

            {edit && <CounterForm sx={{m:1}} editDoc={edit} onClose={handleClose}/>}

        </PageBase> 
    )
}

export default CounterIndex