import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

import ColorSelector from './ColorSelector';
import {getAPI, postAPI, checkUserRoles} from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export default function FormDialog({docId='new', open, alwaysEdit=false, onClose=()=>{}}) {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(alwaysEdit);
    const [doc, setDoc] = useState({})
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const title = docId === 'new' ? "Nueva Notificación" : (edit ? "Edición de Notificación" : "Notificación");

    const currentUser = useSelector(state => state.currentUser);
    let disabled = docId !== 'new' && doc && (!checkUserRoles(currentUser, 
        ['CERRADA'].indexOf(doc.status) > -1 ? ['local-admin'] : ['local-admin']
    ))

    useEffect( () => {
        if (!docId || docId === "new") return;
        getAPI('/notifications/' + docId)
        .then(data => {
            setDoc(data);
        });
    }, [docId]);

    const handleEdit = () => {
      setEdit(!edit)
    }

    const handleSubmit = () => {
        postAPI('/notifications', doc)
        .then( data => {
            enqueueSnackbar("Se agregó mensaje", {variant: 'info'})
            if (onClose) onClose()
        })
    }

    const handleUpdate = (fld) => (ev) => {
        setDoc(v => ({...v, [fld]: ev.target.value}))
    }

    return (
      <Dialog 
        sx={{
            '& .MuiDialog-paper': {
                width: '40vw',  // Set custom width
                height: '60vh', // Set custom height
            },
        }}    
        open={open} 
        fullScreen={fullScreen}
        maxWidth="lg"
        onClose={onClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                { docId !== 'new' && 
                  <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button>
                }
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <DialogContent>

                <Grid container>
                    <Grid item xs={4}>
                        <ColorSelector sx1={{p:1, width: '40%'}} defaultColor="rojo" value={doc.color} onChange={handleUpdate('color')} disabled={disabled}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField sx={{width: '100%'}} label="Mensaje" multiline value={doc.message} onChange={handleUpdate('message')} disabled={disabled}/>
                    </Grid>
                </Grid>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} variant='outlined'>Cancelar</Button>
            <Button onClick={handleSubmit} disabled={!doc.message} variant='outlined'>Guardar</Button>
        </DialogActions>
      </Dialog>
  )
}
