import React, {forwardRef} from 'react'; 
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
//import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {DataGrid} from '../../components'; 

import moment from 'moment';

const columns = (onClick=()=>{}) => ([
    {
        field: 'carpeta',
        headerName: 'OBRA',
        type: 'string',
        renderCell: params => {
            const carpeta = params.value;
            if (onClick && onClick('OBRA')) {
                return <Link href="#" onClick={onClick('OBRA')(params.row)}>{carpeta}</Link>            
            }
        },
        minWidth:80
    },
    {
        field: 'nombre',
        headerName: 'NOMBRE',
        type: 'string',
        renderCell: params => {
            const nombre = params.value;
            if (onClick && onClick('OBRA')) {
                return <Link href="#" onClick={onClick('OBRA')(params.row)}>{nombre}</Link>            
            }
        },
        minWidth:150,
        flex:1
    },
    {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        width: 60,
        renderCell: params => {
            const row = params.row
            return row.status
        }
    },
    {
        field: 'distributorId',
        headerName: 'Distrib.',
        sortable: false,
        renderCell: params => {
            const row = params.value
            return row && row.codigo
        },
        width:50,
    },
    {
        field: 'tiene_planning',
        headerName: 'Planning',
        sortable: false,
        renderCell: params => {
            const row = params.value
            return row ? <CheckIcon color='green' fontSize='13'/> : ""
        },
        width:50,
    },
    {
        field: 'created_at1',
        headerName: 'Dias de Ingreso',
        //type: "date",
        valueGetter: (params) => {
            const fecha = params.row.created_at;
            return fecha && moment(fecha).fromNow('days')
        },
        sortable: false,
        width: 80,
    },
    {
        field: 'cliente',
        headerName: 'Cliente',
        type: 'string',    
        renderCell: (params) => {
            const own = params.value;
            const out = own ? [ own.nombre || "", 
                                own.apellido || "",
                                own.razon_social || ""
                            ].join(' ') : "";
            console.log('client', own)
            const link = (onClick && onClick('CONTACT') && own ) ? (
                <IconButton size='small' onClick={onClick('CONTACT')(own)}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? [<span>{out}</span>, link] : out;
        },
        minWidth: 240,
    },
    {
        field: 'tasksNum',
        headerName: 'Tareas',
        renderCell: (params) => {
            if (params.value) {
                return <Box sx={{color:'#f00'}}>{params.value}</Box>
            }
        },
        width: 60,
    },
    {
        field: 'direccion',
        headerName: 'Direccion',
        type: 'string',
        width: 180,
    },
    {
        field: 'marca',
        headerName: 'Marca',
        type: 'string',
        width: 100,
    },
    {
        field: 'color',
        headerName: 'Color',
        type: 'string',
        width: 140,
    },
    {
        field: 'ownerId',
        headerName: 'Vendedor',
        sortable: false,
        renderCell: (params) => {
           const own = params.value;
           return own && own._id ? own.username : ""
        },
        width: 80,
    },
    {
        field: 'barrio',
        headerName: 'Barrio',
        sortable: false,
        width: 120,
    },
    {
        field: 'localidad',
        headerName: 'Localidad',
        sortable: false,
        width: 120,
    },
    {
        field: 'updated_at1',
        headerName: 'Actualizado',
        //type: "date",
        valueGetter: (params) => {
            const fecha = params.row.updated_at;
            return fecha && moment(fecha).utc().format("YYYY-MM-DD")
        },
        sortable: false,
        width: 110,
    },
    {
        field: 'created_at2',
        headerName: 'Ingresada',
        //type: "date",
        valueGetter: (params) => {
            const fecha = params.row.created_at;
            return fecha && moment(fecha).utc().format("YYYY-MM-DD")
        },
        sortable: false,
        width: 110,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
            <GridActionsCellItem icon={<CreateIcon/>} onClick={onClick('OBRA')(params.row)} label="Edit" />,
        ],
    }
    ])

const ListPage = forwardRef( ({
    docs=[], 
    num=0,
    limit=100, 
    loading=false,
    onClick=()=>{},
    onPageChange=()=>{},
    ...other
}, ref) => {
    
    return (
        <DataGrid
            loading={loading} 
            rows={docs} 
            rowCount={num}
            columns={columns(onClick)} 
            disableColumnFilter
            sortingOrder='null'

            pagination              
            paginationMode="server"
            pageSize={limit}
            onPageChange={onPageChange}

            rowHeight={35} 
            hideFooter={false}
            
            {...other}  
        />    
    )
})

export default ListPage;