import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PlanningTable from '../Stock/PlanningTable';
import TasksTable from '../Task/TasksTable';
import { Attributes } from '../../components';

import moment from 'moment'


const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-title': {
        borderBottom: '1px solid'
    },
    "@media print": {
        '.MuiAttributes-label': {
            fontSize: '9px'
        },
        '.MuiAttributes-text': {
            fontSize: '10px'
        },
        '.MuiAttributes-title': {
            fontSize: '9px'
        },
        'ul, ul li, table td, table th': {
            fontSize: "10px"
        },
    }
}));

const WorkOrderPrint = ({doc, ...other}) => {

    const tipo = doc.tipo === 'fabrica' ? 'OF' : 'OS';

    const formatFecha = (fecha) => {
        return fecha && moment(fecha).utc().format('YYYY-MM-DD')
    }

    const ofRels = tipo === 'OF' ?
        doc.of_rel && `${doc.of_rel.tipo === 'servicio' ? 'OS' : 'OF'}${doc.of_rel.of} (${doc.of_rel.status})` :
        doc.ofs_rel && doc.ofs_rel.map(ii => (`${ii.tipo === 'servicio' ? 'OS' : 'OF'}${ii.of} (${ii.status})`)).join(" | ")

    const direccion = doc.obra && doc.obra && `${doc.obra.barrio} ${doc.obra.lote} ${doc.obra.direccion}`;

    return (
        <Box {...other}>
            <Typography sx={{mb:1}} variant='h6'>
                {tipo === 'OS' ? 'Orden de servicio' : 'Orden de fábrica'} {tipo}-{doc.of} ({doc.status}) para {tipo === 'OF' ? doc.recurso : doc.recursos.join(', ')}
                &nbsp; &nbsp; {doc.obra && `  / OBRA [${doc.obra.carpeta}] ${doc.obra.nombre}`} 
                &nbsp; &nbsp; {doc.pv && `  /  PV [${doc.obra.carpeta}] ${doc.obra.nombre}`}
            </Typography>

            <AttributesStyled
                sizes={{xs: 2}}
                list={
                    [
                        {title: 'LUGAR'},
                        {label: 'Direccion', text: direccion, sizes: {xs:4}},
                        {label: 'Cliente', text: doc.obra && doc.obra.cliente && doc.obra.cliente.fullname, sizes: {xs:3}},
                        {label: 'Telefonos', text: doc.obra && doc.obra.cliente && `${doc.obra.cliente.telefono} / ${doc.obra.cliente.celular}`, sizes: {xs:3}},

                        (tipo !== 'OF' && {title: 'TRANSPORTE'}),
                        (tipo !== 'OF' && {label: 'Patente', text: doc.transporte && doc.transporte.patente }),
                        (tipo !== 'OF' && {label: 'Nombre', text: doc.transporte && doc.transporte.nombre }),
                        (tipo !== 'OF' && {sizes: {xs: 6} }),

                        {title: 'DETALLE'},
                        (tipo === 'OF' && {label: 'Recurso',  text: doc.recurso}),
                        (tipo === 'OS' && {label: 'Recursos',  text: doc.recursos.join(", ")}),
                        {label: 'Categoria', text: doc.categoria},
                        {label: 'Etapa',     text: doc.etapa},

                        {label: 'Cantidad',   text: doc.cantidad},
                        {label: 'UPS',        text: doc.ups},
                        {},

                        {label: 'Reproceso',  text: doc.ups},
                        {label: 'Vendedor',   text: doc.obra && doc.obra.ownerId && doc.obra.ownerId.username},
                        {},
                        {},
                        {},
                        
                        {title: 'DESCRIPCION'},
                        {label: 'Descripcion',  text: doc.descripcion, sizes: {xs:6, sm:10}},

                        {title: 'REFERENCIAS'},
                        {label: 'Obra',     text: doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}`},
                        {label: 'PV',       text: doc.pv && `[${doc.pv.pv}] ${doc.pv.nombre}`},
                        {label: 'Medición', text: doc.measurement && `${doc.measurement.filename}`},
                        {label: 'NP',       text: doc.np && `[${doc.np.np}/${doc.np.status}] ${doc.np.provee ? doc.np.provee.fullname : "-"}`},
                        {},
                        // {},
                        // {label: 'REL',      text: ofRels, sizes: {xs:10}},

                        {title: 'PLANIFICACION'},
                        {label: 'Plan Inicio',         text: formatFecha(doc.plan_fecha_inicio)},
                        {label: 'Plan Finalización',   text: formatFecha(doc.plan_fecha_finalizacion)},
                        {label: 'Plan Duración (d)',   text: doc.plan_duracion},
                        (tipo !== 'OF' ? {label: 'OS Confirmado', text: formatFecha(doc.os_confirmado)} : {}),
                        (tipo !== 'OF' ? {label: 'OS Programado', text: formatFecha(doc.os_programado)} : {}),
                        {},
                        {label: 'Fecha Inicio Real',         text: formatFecha(doc.fecha_inicio)},
                        {label: 'Fecha Finalizacion Real',   text: formatFecha(doc.fecha_finalizacion)},
                        {label: 'Duración (d) Real',         text: doc.duracion},
                        {},
                        {},
                        (tipo === 'OF' && {title: 'ADMIN'}),
                        (tipo === 'OF' && {label: 'Inicio Colocacion',  text: formatFecha(doc.fecha_inicio_colocacion)}),
                        (tipo === 'OF' && {label: 'Fin Colocacion',     text: formatFecha(doc.fecha_fin_colocacion)}),
                        (tipo === 'OF' && {label: 'Fecha Aprobación',   text: formatFecha(doc.fecha_aprobacion)}),
                        (tipo === 'OF' && {label: 'Aprobado Por',       text: doc.approved_by && doc.approved_by.username})
                    ]
                }
            />
            <Typography sx={{float:'right'}} variant="caption">
                Actualizado por {doc.ownerId && doc.ownerId.username} el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}
            </Typography>

            { tipo === 'OS' && 
                <Box sx={{m:1, height: '100%'}}>
                    <Typography sx={{mb:2, mt:2, fontWeight: 600}} variant='h6'>OF transportadas</Typography>
                    {!!ofRels ? ofRels : <Typography>NO TRANSPORTA OFs</Typography>}
            
                    <Typography sx={{mb:2, mt:2, fontWeight: 600}} variant='h6'>Tareas</Typography>
                    <TasksTable hideEdit hideRefs reference={{field:'of', id:doc._id}}/>

                    <Typography sx={{mb:2, mt:2, fontWeight: 600}} variant='h6'>Materiales</Typography>
                    <PlanningTable ofId={doc._id} />
                </Box>
            }

        </Box>

    )
}


const WorkOrderPrintStyled = styled(WorkOrderPrint)(({ theme }) => ({
        '& .MuiPopover-root .MuiModal-root': {
            backgroundColor: 'red'
        },
        "@media print": {
            "@page": {
                "size": "portrait",
                "margin": "10mm 5mm 5mm 5mm",
                "mso-header-margin":"0mm",
                "mso-footer-margin":"0mm",
                "mso-paper-source":0,
            },
            '.MuiTypography-h6': {
                fontSize: 11,
                fontWeight: 600,
                borderBottom: '1px solid'
            },
            '.MuiTypography-caption': {
                fontSize: 9
            },
            '.MuiTableContainer-root': {
                boxShadow: 0
            },
            '.MuiTable-root th': {
                fontSize: '9px',
                fontWeight: 800
            },
            '.MuiTable-root td': {
                fontSize: '8px'
            },
            '.MuiChip-label': {
                fontSize: '8px'
            }
        }
    })
)

export default WorkOrderPrintStyled;