import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

const ColorSelector = ({defaultColor, selectProps={}, ...other}) => {
  // Array of color options
  const colors = [
    { name: "Rojo", value: "#f44336" },
    { name: "Verde", value: "#4caf50" },
    { name: "Azul", value: "#2196f3" },
    { name: "Amarillo", value: "#ffeb3b" },
    { name: "Violeta", value: "#9c27b0" },
  ];

  const defaultHex = colors.find((color) => color.name.toLowerCase() === defaultColor.toLowerCase())?.value || "";

  const [color, setColor] = useState(defaultHex); // State initialized with the hex value

  const handleChange = (event) => {
    setColor(event.target.value); // Update the selected color
  };

  return (
      <FormControl sx={{width: 140}} fullWidth>
        <InputLabel id="color-selector-label">Seleccionar Color</InputLabel>
        <Select
          sx={{
            "& MuiSelectSelect": {
                display: 'inline-flex'
            }
          }}
          size='small'
          labelId="color-selector-label"
          value={color}
          onChange={handleChange}
          label="Selección de Color"
        >
          {colors.map((colorOption) => (
            <MenuItem key={colorOption.value} value={colorOption.value}>
              <ListItemIcon>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: colorOption.value,
                    borderRadius: "50%",
                    border: "1px solid #ccc",
                  }}
                />
                </ListItemIcon>
              <Typography>{colorOption.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

// Default Props
ColorSelector.defaultProps = {
    defaultColor: "",
};
  
  
export default ColorSelector;