import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { SelectField } from '../../components';
import {getAPI} from '../../utils' ;

export const AlmacenField = ({partId="", partCol="", noneteable=false, onChange, ...other}) => {
    debugger
    const [options, setOptions] = useState([])
    const warehouses = useSelector( state => state.warehouses || [] );

    useEffect( () => {
        if (partId) {
            debugger
            getAPI('/stock/inventory', {articuloId:partId, articuloColor:partCol, level: 'ubi'})
            .then(data => {
                const opts = data.list.map( it => ({
                    label: [it.almacen.codigo, it.ubi1, it.ubi2, it.ubi3, it.exist, it.reserved ].join(' | '), 
                    value: it.almacen._id + "|" + it.ubi1 + "|" + it.ubi2 + "|" + it.ubi3,
                    almacen: {_id: it.almacen._id}, 
                    ubi1: it.ubi1, 
                    ubi2: it.ubi2, 
                    ubi3: it.ubi3, 
                    exist: it.exist, 
                    reserved: it.reserved
                }))
                setOptions(opts)
                if (onChange) {
                    // simula un evento y manda extra data
                    opts && onChange('almacen')({target: {value: opts[0].value}}, {props:{"data-other":opts[0]}});
                }
            })
            .catch(err => {
                console.error(err)
            });    
        } else {
            const options = warehouses.filter(it => noneteable || it.noneteable == 0).map( it => ({label: it.codigo, value: it._id}))
            setOptions(options)
        }
    }, [partId, partCol, warehouses, noneteable])

    return (
        <SelectField label="Almacen" options={options} onChange={onChange} {...other} />
    )
}