import ReactDOMServer from 'react-dom/server';
import Button from '@mui/material/Button';
import { to_mime } from '../utils';

const ejemplo = () => {
    const styles = {
        head1:  {color:"red", width: '30%', textAlign: 'center'},
        number: {textAlign:'right'} 
    }
    const cols = [
        {title: 'Uno', field: 'name', headClassName: 'head1', rowClassName: 'row'},
        {title: 'DOS', field: 'valor', headClassName: 'head1', rowClassName: 'number'}
    ]
    const rows = [
        {name: 'Martin'},
        {name: 'Juan', valor:123}
    ]
    return {styles, cols, rows}
}

const defaultStyle = {
    head1:  {backgroundColor:"#dbe0f0", textAlign: 'center'},
    number: {textAlign:'right'},
    row:    {textAlign:"left"}             
}

const ReportTable = ({reportName="", title="", subTitle="", cols=[], footer=[], styles=defaultStyle, rows=[]}) => {
    const titleStyle = styles['title'] || {};
    const subTitleStyle = styles['subTitle'] || {fontSize: '10px'}; 
    return  (
        <>
            <h3 style={titleStyle}>{reportName || title}</h3>
            <p style={subTitleStyle}>{subTitle}</p>
            <table>
                <thead>
                    <tr>
                        {
                            cols.map( it => {
                                return (<th style={styles[it.headClassName] || defaultStyle['head1']}>{it.title}</th>)
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map( row => {
                            return (
                                <tr>
                                    {
                                        cols.map( col => {
                                            const field = col.field;
                                            const valor = col.transform ? col.transform(row[field], row) : row[field];
                                            return (<td style={styles[col.rowClassName]}><span>{field && valor}</span></td>)
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {/* {footer.map( it => (
                <p>{it}</p>
            ))} */}
        </>
    )

}


const ExportButton = ({configReport=()=>{}, children, Component=ReportTable, ...other}) => {

    const handleExport = async () => {
        debugger

        const {title, subTitle, cols, rows, footer=[], styles} = await configReport()
        
        const final = ReactDOMServer.renderToString(<Component title={title} subTitle={subTitle} cols={cols} rows={rows} footer={footer} styles={styles}/>)
        return to_mime('excelFile', final)
    }

    //const {styles, cols, rows} = ejemplo()
    //return to_html({reportName: 'reporte', styles, cols, rows})
    
    return (
        <Button sx={{m:1}} size='small' variant='outlined' onClick={handleExport} {...other}>{children}</Button>        
    )
}

export default ExportButton;