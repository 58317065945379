import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';

import { HeaderBar } from '../../components';
import {checkUserRoles} from "../../utils";

import {POrderRecepcionTable} from './POrderRecepcionTable';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalDialog({open, tipo, recepcion={}, onCancel, onSubmit}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'compras-edit'])
    const title = recepcion._id === 'new' ? "Nueva Recepción" : "Edición de Recepción #" + recepcion.rec;

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open} 
        onClose={onCancel}
        TransitionComponent={Transition}
      >
        <HeaderBar 
          title={title}
          actions={[
            <IconButton
                edge="start"
                color="inherit"
                onClick={onCancel}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
          ]}
        />

        <DialogContent>

          <POrderRecepcionTable tipo={tipo} recepcion={recepcion} onCancel={onCancel} onSubmit={onSubmit}/>
          {/*JSON.stringify(recepcion && recepcion.detail && recepcion.detail[0].recibido)*/}

        </DialogContent>
      </Dialog>
  )
}
