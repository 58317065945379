import React, {useState, useEffect} from 'react'
import moment from 'moment' 
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { Dialog } from '@mui/material'
import MessageBox from './MessageBox'
import { DatePicker } from '@mui/x-date-pickers'

const EditDate = ({title, value, onChange, children}) => {
    const [edit, setEdit] = useState(false)
    const [fecha, setFecha] = useState(value)
    useEffect( () => {
        setFecha(value)
    }, [value])
    const handleChange = (ff) => {
        debugger
        setFecha(ff)
    }
    const handleClose = (valor) => {
        debugger
        setEdit(false)
        if (valor && onChange) {
            onChange(fecha)
        }
    }
    return (
        <>
            {children} 
            <IconButton onClick={v => setEdit(true)}>
                <EditIcon fontSize='small'/>
            </IconButton>

            <MessageBox
                open={edit}
                title={title}
                onClose={handleClose}
            >
                <DatePicker format='YYYY-MM-DD' value={fecha} onChange={handleChange}/>
            </MessageBox>
        </>
    )
}

export default EditDate