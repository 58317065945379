import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileList from '../Attachments/FileList';
import {Attributes, AdjuntoToggle} from '../../components';
import WorkOrderTable from '../WorkOrder/WorkOrderTable';
import TasksTable from '../Task/TasksTable';
import TabPanel from '../Project/TabPanel';
import StockPlanningShowEdit from '../Stock/StockPlanningShowEdit';
import POrderTable from '../POrder/POrderTable';
import moment from 'moment';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {postAPI, getAPI, useTabs, checkUserRoles, activityLog, defaultTheme} from '../../utils';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
        color: '#333',
        //backgroundColor: '#d7f1ff',
        padding: '10px',
    },
    ganada: {
        backgroundColor: '#8bc34a'
    },
    perdida: {
        backgroundColor: "#f20b56ed"
    },
    presupuestar: {
        backgroundColor: '#ffeb3b'
    },
    textoOverflow: {        
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        maxWidth: '87%', 
        display: 'inline-block'
    },
    title: {
        fontSize: "14px",
        fontWeight: 800  
    },
    "@media print": {
        titleUnder: {
            fontSize: "14px",
            padding: "10px",
            width: "100%",
            borderBottom: '1px solid'
        },
    }
}), { defaultTheme } )

const AttributesStyled = styled(Attributes)(
    ({ theme }) => ({
        ".MuiAttributes-title": {
            fontSize: "14px",
            fontWeight: 800,
        },
    })
)


const PostSaleShow = ({
        doc={},
        onReload=()=>{},
        ...other
    }) => {

    let classes = useStyles();
    const {appendTab} = useTabs();
    const { enqueueSnackbar } = useSnackbar();
    const [tab, setTab] = useState(0);
    // const [doc, setDoc] = useState({});
    const [reload, setReload] = useState(false);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [adjuntos, setAdjuntos] = useState({ficha: false, presu: false, foto: false});

    const currentUser = useSelector(state => state.currentUser);
    const editable = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'posventa-edit'])
    const editableTask = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'posventa-edit', 'servicio-edit', 'obra-tareas']);
    const disabled = ['TCP','T','CO','INCO'].indexOf(doc['status']) > -1;

    const label = "PV-" + doc.pv ;

    // useEffect( () => {
    //     debugger
    //     setLoading(true);
    //     setReload(false)
    //     // reads data to Edit instead of using datagrid row
    //     if ( docId === 'new' ) return 
    //     getAPI('/postsale/postsales/' + docId )
    //     .then(data => {
    //         setLoading(false)
    //         if (data) setDoc(data)
    //     })
    //     .catch(err => {
    //         debugger
    //         console.log(err.message)
    //         enqueueSnackbar(err.message, {variant: "error"})
    //       });   
    // }, [docId, reload, enqueueSnackbar])
    
    const handleChangeTab = (ev, newValue) => {
        setTab(newValue)
    }

    const getDireccion = () => {
        let out = ""
        if (doc.barrio) {
            out = doc.barrio + " " + doc.lote + ", "
        }
        out += (doc.direccion || "SIN")
        out += "-" + (doc.partido  || "SIN")
        out += "-" + (doc.localidad  || "SIN")
        out += "-" + (doc.provincia  || "SIN")
        out += "-" + (doc.pais  || "SIN")
        return out
    }

    // const contacto = doc.contacto ? doc.contacto._id : {}; 
    const getNameCont = () => {
      if (!doc.contacto) return "";
        return <Link href="#" onClick={handleClick('CONTACT')(doc.contacto)}>{doc.contacto.fullname}</Link>            
    }

    // const obra = doc.obra ? doc.obra._id : {}; 
    const getNameObra = () => {
      if (!doc.obra) return "";
        return <Link href="#" onClick={handleClick('OBRA')(doc.obra)}>{doc.obra.nombre}</Link>            
    }

    const handleClick = tipo => item => (ev) => {
      debugger
      ev.preventDefault();
      if (item.id) appendTab(tipo)(item);
    }
    const handleAdjuntos = (tipo) => (ev) => {
        setAdjuntos(old => ({...old, [tipo]: !adjuntos[tipo]}))
    }
    const handleUpload = tipoAdjunto => uploadedFile => {
        postAPI('/postsale/postsales/addToSet/' + doc['_id'], {[tipoAdjunto]: uploadedFile})
        .then(async data => {
          debugger
          let msg = `Se agregó el archivo "${uploadedFile.filename}" (${tipoAdjunto}). `
          activityLog({label, app:'posventa', docId: doc._id, msg: msg, currentUser})

          enqueueSnackbar(msg, {variant: 'info'})
          setReload(true);
          if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  
    }
    const handleUploadDelete = tipoAdjunto => file => ev => {
        debugger
        const nuevo = doc[tipoAdjunto].filter(it => it.filename !== file.filename);
        return postAPI('/postsale/postsales/' + doc['_id'], {[tipoAdjunto]: nuevo})
        .then(async data => {
            debugger
            let msg = `Se borró el archivo "${file.filename}" (${tipoAdjunto}). `
            activityLog({label, app:'posventa', docId: doc._id, msg: msg, currentUser})

            enqueueSnackbar(msg, {variant: 'info'})
            setReload(true);
            if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
    const handleNuevo = tipo => (ev) => {
        debugger
        if (tipo) {
            appendTab(tipo)({_id: 'new', tipo: tipo === 'WORK-F' ? 'fabrica' : 'servicio', pv: doc})
        }
    }

    return (
        <Box {...other}>

            <Tabs
                sx={{'@media print': {display: 'none'}}}
                centered
                value={tab} 
                onChange={handleChangeTab} 
            >
                <Tab id={"obra-0"}  label={"Detalle"} />
                {/* <Tab id={"obra-1"}  label={"Requerimientos"} /> */}
                <Tab id={"obra-2"}  label={"Fabricación"} />
                <Tab id={"obra-21"}  label={"Servicios"} />
                <Tab id={"obra-3"}  label={"Tareas"} />
                <Tab id={"obra-4"}  label={"Compras"} />
            </Tabs>

            <TabPanel index={0} value={tab} sx={{height: 'calc(100% - 220px)'}}>

                <AttributesStyled 
                    sizes={{xs:6, sm:2}} 
                    list={
                        [
                            {title: 'TRABAJO'},
                            {label: 'Tipo',         text: doc.tipo},
                            {label: 'Descripción',  text: doc.descripcion ? doc.descripcion : "SIN", sizes: {xs:8}},

                            {title: 'DATOS'},
                            {label: 'Nombre',       text: doc.nombre},
                            {label: 'Status',       text: doc.status},
                            {label: 'En Garantía',  text: doc.en_garantia},
                            {label: 'Presupuesto',  text: doc.presupuesto},
                            {label: 'Valor',        text: doc.valor},

                            {title: 'CONTACTOS'},
                            // {label: 'Contacto Obra',text:  Object.keys(doc.contacto).length !== 0 ? doc.contacto && doc.contacto.fullname : "" , sizes: {xs:6}},
                            {label: 'Email',           text: doc.email},
                            {label: 'Teléfono',        text: doc.telefono},
                            {label: 'Zona',         text: doc.zona},
                            {label: 'Contacto',        text: doc.contacto ? getNameCont() : 'SIN'},
                            {},
                            {},
                            {label: 'Dirección',    text: getDireccion(), sizes: {xs:10}},

                            {title: 'REFERENCIAS'},
                            {label: 'Referencia',        text: doc.referencia, sizes: {xs:4, sm:2}},
                            {label: 'Obra',            text: doc.obra ? getNameObra() : 'SIN'},
                            {label: 'Distribuidor',      text: doc.distributorId && doc.distributorId.nombre, sizes: {xs:4, sm:2}},
                            {},
                            {},

                            {title: 'FECHAS'},
                            {label: 'Fecha Aceptada',   text: doc.fecha_aceptada ? moment(doc.fecha_aceptada).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Aprobada',   text: doc.fecha_aprobada ? moment(doc.fecha_aprobada).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Enviada',    text: doc.fecha_enviada ? moment(doc.fecha_enviada).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Cerrada',   text: doc.fecha_cerrada ? moment(doc.fecha_cerrada).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Recepcion',   text: doc.fecha_recepcion ? moment(doc.fecha_recepcion).utc().format('YYYY-MM-DD') : "SIN"},

                            {title: 'ARCHIVOS'},        
                            {   
                                label: (
                                    <AdjuntoToggle editable={editable} edit={!!adjuntos['ficha']} onClick={handleAdjuntos('ficha')}>
                                        Archivo Ficha
                                    </AdjuntoToggle>
                                ),
                                children: <FileList 
                                            folder="/ficha"
                                            docs={doc.archivo_ficha} 
                                            editable={adjuntos['ficha']}
                                            onUploaded={handleUpload('archivo_ficha')}
                                            onDelete={handleUploadDelete('archivo_ficha')}                                  
                                        />,
                                sizes: {xs:3}
                            },
                            {   
                                label: (
                                    <AdjuntoToggle editable={editable} edit={adjuntos.presu} onClick={handleAdjuntos('presu')}>
                                        Archivo Presupuesto
                                    </AdjuntoToggle>
                                ),
                                children: <FileList 
                                            folder="/presupuesto"
                                            docs={doc.archivo_presupuesto} 
                                            editable={adjuntos['presu']}
                                            onUploaded={handleUpload('archivo_presupuesto')}
                                            onDelete={handleUploadDelete('archivo_presupuesto')}                                                      
                                        />,
                                sizes: {xs:3}
                            },
                            {   
                                label: (
                                    <AdjuntoToggle editable={editable} edit={adjuntos.foto} onClick={handleAdjuntos('foto')}>
                                        Archivo Foto
                                    </AdjuntoToggle>
                                ),
                                children: <FileList 
                                            folder="/foto"
                                            docs={doc.archivo_foto} 
                                            editable={adjuntos['foto']}
                                            onUploaded={handleUpload('archivo_foto')}
                                            onDelete={handleUploadDelete('archivo_foto')}                                                      
                                        />, 
                                sizes: {xs:3}
                            },


                        ]
                    }
                />
            </TabPanel>

            {/* <TabPanel index={1} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <StockPlanningShowEdit project={doc}/>
            </TabPanel> */}


            <TabPanel index={1} value={tab} sx={{height: 'calc(100% - 220px)'}}> 
                <AttributesStyled 
                    sizes={{xs:12, sm:2}} 
                    list={
                        [
                            {   
                                title: <Box>FABRICACIÓN<Button size='small' disabled={disabled} variant='outlined' onClick={handleNuevo('WORK-F')}>NUEVO</Button></Box>, 
                                sizes: {xs:12, sm:2}
                            },
                            {label: '', text: <WorkOrderTable tipo='fabrica' pvId={doc._id} onClick={handleClick}/>, sizes:{xs: 12}},
                        ]
                    }
                />
            </TabPanel>

            <TabPanel index={2} value={tab} sx={{height: 'calc(100% - 220px)'}}> 
                <AttributesStyled 
                    sizes={{xs:12, sm:2}} 
                    list={
                        [
                            {
                                title: <Box>SERVICIOS <Button size='small' disabled={disabled} variant='outlined' onClick={handleNuevo('WORK-S')}>NUEVO</Button></Box>, 
                                sizes: {xs:12, sm:2}
                            },
                            {label: '', text: <WorkOrderTable tipo='servicio' pvId={doc._id} onClick={handleClick}/>, sizes:{xs: 12}},
                        ]
                    }
                />
            </TabPanel>


            <TabPanel index={3} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <AttributesStyled 
                    sizes={{xs:12}} 
                    list={
                        [
                            {title: 'TAREAS', sizes: {xs:12}},
                            {label: '', text: <TasksTable 
                                                // sx={{height: 'calc(100% - 100px)'}} 
                                                reference={{label: "PV-"+doc.pv, app:'posventa', field:'pv', id:doc._id}}
                                                editable={editableTask}
                                              />, 
                                              sizes:{ xs: 12}},
                        
                        ]
                    }
                />
            </TabPanel>


            <TabPanel index={4} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <AttributesStyled 
                    sizes={{xs:12}} 
                    list={
                        [
                            {title: 'COMPRAS', sizes: {xs:12}},
                            {label: '', text: <POrderTable pvId={doc._id}/>, sizes:{xs: 12}},
                        ]
                    }
                />
            </TabPanel>


        </Box>
    );
};

export default PostSaleShow;

