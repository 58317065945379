import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

function MessageBox({ open, title, children, onClose, onlyCancel=false, ...other }) {
  const handleClose = (valor) => () => {
    if (onClose) {
      onClose(valor);
    }
  };

  return (
    <Dialog onClose={handleClose(false)} open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose(false)}>Cancelar</Button>
          {!onlyCancel && <Button onClick={handleClose(true)} variant='outlined'>Aceptar</Button>}
        </DialogActions>
    </Dialog>
  );
}

export default MessageBox