import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from "react-redux";
import { reconnectNow, reconnectAPI, useInterval } from '../../utils';
import Login from './Login';
import { useUsersChannel } from './WebSocketProvider';
import CircularProgressPage from './CircularProgressPage';

const reconnect = async (dispatch, navigate) => {
    try {
        console.log('PERSIST USER - reconnecting')
        reconnectNow().catch(err => {
            console.log(err)
            navigate('/login', {replace: true})
        })
    } catch(err) {
        console.log('PERSIST USER - reconnect FAILED')
        debugger
        navigate('/login', {replace: true})
    }
}

const useMouseEventCounter = () => {
    const mouseEventCount = useRef(0);
    const prevCountRef = useRef(0);
  
    useEffect(() => {
      const handleMouseEvent = () => {
        mouseEventCount.current += 1; // Increment the counter without causing a re-render
      };
  
      window.addEventListener('mousemove', handleMouseEvent);
      window.addEventListener('click', handleMouseEvent);
  
      return () => {
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('click', handleMouseEvent);
      };
    }, []);
  
    const getMouseEventDifference = () => {
      const difference = mouseEventCount.current - prevCountRef.current;
      prevCountRef.current = mouseEventCount.current; // Update the ref
      return difference;
    };
  
    return { mouseEventCount, getMouseEventDifference };
};

const PersistUser = ({ reconnectionTimeMs = 5000, reconnectionCycles = 5, children, devel = true }) => {
    const [show, setShow] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
  
    const org = Cookies.get('org');
    const token = Cookies.get('token');
    const currentUser = useSelector((state) => state.currentUser);
  
    const { isReady, connectUser, reportActivity, isUserValid } = useUsersChannel((msg) => {
      if (currentUser && msg?.data) {
        if (msg.data.logoutUser === currentUser._id) {
          enqueueSnackbar("Se forzó el logout!", {variant: 'warning'})
          navigate('/logout', { replace: true });
        }
        if (msg.data.reloadUser === currentUser._id) {
          enqueueSnackbar("Se esta recargado la configuración de este usuario!", {variant: 'warning'})
          reconnectNow();
        }
        if (msg.data.reloadConfigs) {
          enqueueSnackbar("Se esta recargado la configuración de la aplicación!", {variant: 'warning'})
          reconnectNow();
        }
        if (msg.data.appVersion) {
          dispatch({
            type: 'APPVERSION', 
            appVersion: msg.data.appVersion, 
          })
        }
      }
    });
  
    const { getMouseEventDifference } = useMouseEventCounter();
    useEffect(() => {
      const interval = setInterval(() => {
        const mouseEventDifference = getMouseEventDifference();
        if (isReady && currentUser) {
          reportActivity(currentUser._id, location.pathname, mouseEventDifference);
          if (mouseEventDifference > 0) {
            console.log('[PersistUser] user-activity', mouseEventDifference)
          }
        }
      }, 5000);
      return () => clearInterval(interval);
    }, [isReady, currentUser, reportActivity, location.pathname]);
  
    useEffect(() => {
      if (isReady && currentUser) {
        connectUser(org, token, currentUser);
      }
    }, [isReady, connectUser, currentUser, org, token]);
  
    useInterval(() => {
      if (isReady && currentUser) {
        isUserValid(currentUser._id);
      }
    }, 10000);
  
    useEffect(() => {
      setShow(null);
  
      if (!org || !token || (currentUser && !currentUser.active)) {
        setShow('login');
      }
      if (org && token && !currentUser) {
        reconnectNow();
        setShow('progress');
      }
    }, [token, org, currentUser]);
  
    // Reconnection logic
    const recomputeRef = useRef(0);
    useInterval(() => {
      if (org && token && !currentUser) {
        recomputeRef.current += 1;
        if (recomputeRef.current === 3) {
          recomputeRef.current = 0;
          setShow('login');
        }
      }
    }, 5000);
  
    if (show === 'progress') {
      return <CircularProgressPage />;
    }
    if (show === 'login') {
      return <Login />;
    }
    return children;
  };

export default PersistUser;