import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MessageBox, SelectField } from "../../components";
import PartAutocomplete from '../Part/PartAutocomplete';
import OperationAutocomplete from '../Settings/Operation/OperationAutocomplete';
import {options_moneda} from '../../appConfig';


const PriceListNewPart = ({tipo, open, value={}, onCancel, onSubmit}) => {
    const [fields, setFields] = useState({moneda: "USD"})

    useEffect( () => {
        if (open) {
            setFields({})
        } else {
            setFields(v => ({...v, ...value}))
        }
    }, [value, open])

    const handleChange = fld => (ev, part) => {
        debugger
        const valor = ev.target.value
        setFields(v => ({...v, [fld]: valor}))    
    }
    const handlePartChange = (ev, part) => {
        setFields(v => ({...v, part}))
    }
    const handleSubmit = (ev) => {
        if (onSubmit) {
            onSubmit(fields)
        }
    }

    return (
        <MessageBox 
            title={"Agrega nuevo " + (tipo==='1' ? 'articulo':'servicio')}
            open={open} 
            onClose={onCancel}
            onlyCancel={true}
            maxWidth='sm'
            fullWidth={true}
        >
            <Box sx={{m:1}}>
                <Grid container spacing={2}>
                    <Grid xs={4} item>
                        {tipo == '1' && 
                            <PartAutocomplete
                                sx={{width: '100%'}}
                                value={fields['part']}
                                onChange={handlePartChange}
                            />
                        }
                        {tipo == '2' && 
                            <OperationAutocomplete
                                sx={{width: '100%'}}
                                value={fields['part']}
                                onChange={handlePartChange}
                            />
                        }
                    </Grid>
                    <Grid xs={4} item>
                        <TextField 
                            label="Precio"
                            value={fields['precio']}
                            size='small'
                            onChange={handleChange('precio')}
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField 
                            label="Moneda"
                            value={fields['moneda']}
                            size='small'
                            options={options_moneda}
                            onChange={handleChange('moneda')}
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <Button variant='outlined' onClick={handleSubmit}>Agregar</Button>
                    </Grid>
                </Grid>

            </Box>
        </MessageBox>
    )    
}

export default PriceListNewPart;