import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import {PageBase, PageTitle, MessageBox, PrintPreview} from '../../components'; 
import {getAPI, postAPI, deleteAPI, useTabs, activityLog} from '../../utils' ;

import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import StockMaterialPrint from './StockMaterialPrint';
import StockMoveEditContainer from './StockMoveEditContainer';

const StockMoveEdit = forwardRef( ({docId, tipo, onReload=()=>{}}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [doc, setDoc] = useState({_id: docId, tipo, articulo_color: '-1'});
    const [origDoc, setOrigDoc] = useState({});
    const [colors, setColors] = useState([]);
    const [edit, setEdit] = useState(docId === 'new');
    const [error, setError] = useState({});
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const {updateCurrentTab, removeCurrentTab} = useTabs();

    const currentUser = useSelector(state => state.currentUser);
    
    useEffect( () => {
        if (docId === 'new') {
            if (tipo === 'TRAN') {
                setDoc(tt => ({...tt, linkeado: {_id: 'new', tipo: 'TRAN-LINK'}}))
            }
            if (tipo === 'AJS-COL2') {
                setDoc(tt => ({...tt, linkeado: {_id: 'new', tipo: 'AJS-COL2'}}))
            }

        }
    }, [docId, tipo])

    useEffect( () => {
        if (!docId || docId === 'new') return;
        debugger

        setLoading(true)
        getAPI('/stock/movements/'+docId)
        .then(data => {
            debugger
            setDoc(data);
            setOrigDoc(data);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }, [docId, enqueueSnackbar])

    // busca art con colores y inventario solo para los tipos de mov TRAN, AJS-COL
    useEffect( () => {
        if (doc && doc.articulo && ['TRAN', 'AJS-COL', 'AJS-COL2'].indexOf(tipo) > -1 ) {
            debugger
            getAPI('/stock/inventory', {"articuloId": doc.articulo._id, colorOpts:'1'})
            .then( data => {
                console.log(data)
                if (data && data.list) {
                    const oo = {}
                    data.list.map( it => {
                        if (!!it.articulo_color) {
                            oo[it.articulo_color] = 1;
                        } else {
                            oo['-1'] = 1;
                        }
                    })
                    const cols = ['SIN', ...Object.keys(oo)];
                    setColors(cols);
                    if (edit && !doc.articulo_color) {
                        setDoc( ll => ({...ll, articulo_color: cols[0]}))
                    }
                }
            })
        }
    }, [doc && doc.articulo, edit, tipo])

    const validacion = () => {
        debugger
        const err = {}
        if (doc.articulo === undefined) {
            err['articulo'] = "Se debe completar"
        }
        if (doc.almacen === undefined) {
            err['almacen'] = "Se debe completar"
        }
        if (doc.cantidad_ori === undefined) {
            err['cantidad_ori'] = "Se debe completar"
        }
        if (doc.udm_ori === undefined) {
            err['udm_ori'] = "Se debe completar"
        }
        if (doc.tipo === 'TRAN' && doc.linkeado && !doc.linkeado.almacen) {
            err['almacen.linkeado'] = "Se debe completar"
        }
        if (doc.articulo_color === "-1" || doc.articulo_color === "SIN") {
            doc.articulo_color = ""
        }
        return err
    }
    const handleSubmit = () => {        
        const err = validacion()
        if (Object.keys(err).length > 0) {
            setError(err)
            return
        }

        setLoading(true)
        let esNuevo = false;
        if (doc._id === 'new') {
            delete doc._id
            esNuevo = true;
        }
        if (doc.linkeado && doc.linkeado._id === 'new') {
            delete doc.linkeado._id
        }
        postAPI('/stock/movements/', {doc})
        .then(newDoc => {
            debugger
            const docLabel = `${newDoc.tipo}-${newDoc.comprobante_int}`;
            let msg = esNuevo ? "Se creó nuevo registro" : "Se modificó el registro: "
            const diffMsg = [];
            if (origDoc._id) {
                if (origDoc.articulo._id !== newDoc.articulo._id) {
                    diffMsg.push("articulo" + origDoc.articulo.codigo + " => " + newDoc.articulo.codigo)
                }
                if (origDoc.articulo_color !== newDoc.articulo_color) {
                    diffMsg.push("color" + origDoc.articulo_color + " => " + newDoc.articulo_color)
                }
                if (origDoc.cantidad_ori !== newDoc.cantidad_ori) {
                    diffMsg.push("cantidad original" + origDoc.cantidad_ori + " => " + newDoc.cantidad_ori)
                }
                if (origDoc.udm_ori !== newDoc.udm_ori) {
                    diffMsg.push("udm original" + origDoc.udm_ori + " => " + newDoc.udm_ori)
                }
                if (origDoc.almacen._id !== newDoc.almacen._id) {
                    diffMsg.push("almacen salida " + origDoc.almacen.codigo + " => " + newDoc.almacen.codigo)
                }
                if (origDoc.linkeado.almacen._id !== newDoc.linkeado.almacen._id) {
                    diffMsg.push("almacen entrada " + origDoc.linkeado.almacen.codigo + " => " + newDoc.linkeado.almacen.codigo)
                }
                if (origDoc.ubi1 !== newDoc.ubi1) {
                    diffMsg.push("almacen.ubi1 " + origDoc.ubi1 + " => " + newDoc.ubi1)
                }
                if (origDoc.ubi2 !== newDoc.ubi2) {
                    diffMsg.push("almacen.ubi2 " + origDoc.ubi2 + " => " + newDoc.ubi2)
                }
                if (origDoc.ubi3 !== newDoc.ubi3) {
                    diffMsg.push("almacen.ubi3 " + origDoc.ubi3 + " => " + newDoc.ubi3)
                }
                if (origDoc.linkeado.ubi1 !== newDoc.linkeado.ubi1) {
                    diffMsg.push("link.ubi1 " + origDoc.linkeado.ubi1 + " => " + newDoc.linkeado.ubi1)
                }
                if (origDoc.linkeado.ubi2 !== newDoc.linkeado.ubi2) {
                    diffMsg.push("link.ubi2 " + origDoc.linkeado.ubi2 + " => " + newDoc.linkeado.ubi2)
                }
                if (origDoc.linkeado.ubi3 !== newDoc.linkeado.ubi3) {
                    diffMsg.push("link.ubi3 " + origDoc.linkeado.ubi3 + " => " + newDoc.linkeado.ubi3)
                }
                msg = msg + diffMsg.join(', ')
            }
            enqueueSnackbar(msg, {variant: "info"});
            activityLog({label: docLabel, app:'stock', docId: newDoc._id, msg, currentUser});

            setError({})
            setLoading(false);
            setDoc(newDoc);
            setOrigDoc(newDoc);
            setEdit(false);
            updateCurrentTab("MOV")(newDoc);
        })
        .catch(err => {
            if (esNuevo) { // repone el new!!!
                setDoc(ll => ({...ll, _id: 'new'}))
            }
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }

    const handleDeleteVerification = () => {
        setDialog(true)
    }
    const handleDelete = (st) => {
        if (!st) {
            setDialog(false)
            return
        }

        setLoading(true);
        deleteAPI('/stock/movements/'+doc._id)
        .then(data => {
            debugger
            const docLabel = `${doc.tipo}-${doc.comprobante_int}`;

            const msg = "Se borró el movimiento"
            activityLog({label: docLabel, app:'stock', docId: doc._id, msg, currentUser});
            enqueueSnackbar(msg, {variant: "info"});

            setLoading(false);
            setDialog(false)
            if (onReload) onReload(true)
            removeCurrentTab()
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }

    const handleChange = useCallback( fld => (ev, doc1) => {
        debugger
        if (fld === 'articulo') {
            // const art = doc1 ? doc1.articulo : "";
            const art = doc1;
            setDoc(ll => ({
                ...ll,
                articulo: art, 
                articulo_color: '-1',
                // articulo_color: doc1.articulo_color,
                almacen: doc1 && doc1.deposito_almacen, 
                ubi1: doc1 && doc1.deposito_ubi1, 
                ubi2: doc1 && doc1.deposito_ubi2, 
                ubi3: doc1 && doc1.deposito_ubi3, 
                udm_ori: doc1 && doc1.deposito_udm, 
                udm: doc1 && doc1.deposito_udm,
            }))
            setColors([])

            // para cuando limpia articulo que limpie almacen de salida
            if (!doc1) { 
                setDoc(ll => ({
                    ...ll,
                    almacen: undefined,
                    articulo_color: undefined,
                    ubi1: undefined,
                    ubi2: undefined,
                    ubi3: undefined,
                }))
            }
        } else {
            let valor = ev && ev.target && ev.target.value !== undefined ? ev.target.value : doc1;
            debugger
            if (fld === 'almacen') {

                let other;
                if (doc1 && doc1['props'] && doc1['props']['data-other'] && Object.keys(doc1['props']['data-other']).length) {
                    other = doc1['props']['data-other'];
                } else {
                    other = {almacen: {_id: doc1.props['value']}}
                }
                const {almacen, ubi1="", ubi2="", ubi3="", exist=0, reserved=0} = other
                setDoc(ll => ({
                    ...ll, 
                    almacen,
                    ubi1, ubi2, ubi3,
                    exist, reserved
                }))
            } else {
                setDoc(ll => ({...ll, [fld]: valor}))
            }
        }
    }, [])
    const handleChangeLink = useCallback( fld => (ev, doc1) => {
        const valor = ev && ev.target && ev.target.value !== undefined ? ev.target.value : doc1;
        debugger

        if (fld === 'almacen') {
            const linkeado = {...doc.linkeado, [fld]: {_id: valor}};
            setDoc(ll => ({...ll, linkeado}))
        } else {
            const linkeado = {...doc.linkeado, [fld]: valor};
            setDoc(ll => ({...ll, linkeado}))
        }
    }, [doc])

    const handleCancel = () => {
        setEdit(false)
        if (onReload) onReload()
    }
    const handleEdit = () => {
        setEdit(!edit)
    }

    const title = doc ? (doc._id !== 'new' ? `${edit ? 'Edición de ' : ""} Movimiento ${doc.tipo}-${doc.comprobante_int}` : `Nuevo movimiento ${doc.tipo}`) : ""
    return (
      <PageBase 
          paperProps={{
            p:1,
            width:'100%',
            height: 'calc(100% - 96px)' 
            //height: 'calc(100% - 98px)'
          }} 
          title={
            <PageTitle>{title}</PageTitle> 
          }
          actions={
            <Stack direction='row' spacing={1}>
                <Button variant='outlined' size='small' onClick={handleEdit}>{edit ? 'Cancelar': 'Editar'}</Button>
                {doc._id !== 'new' && 
                <>
                    <Button variant='outlined' size='small' onClick={handleDeleteVerification}>Borrar</Button>
                    <PrintPreview 
                        sx={{float:'right', color: 'inherit'}} 
                        component={
                            <StockMaterialPrint 
                                doc={{
                                    tipo:doc.tipo, comprobante:doc.comprobante_int, 
                                    component: <StockMoveEditContainer 
                                                    edit={false} 
                                                    doc={doc} 
                                                    error={error} 
                                                    colors={colors} 
                                                    handleChange={handleChange} 
                                                    handleChangeLink={handleChangeLink}
                                                />
                                }}
                            />
                        }
                    />

                    <ActivityOpenClose sx={{float: 'right'}} app='stock' docId={docId}/>
                </>}
            </Stack>
          }      
      >
       
        <StockMoveEditContainer
            edit={edit} 
            doc={doc} 
            error={error} 
            colors={colors}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleChange={handleChange}
            handleChangeLink={handleChangeLink}
        />

        <MessageBox 
            title="Esta seguro que quiere eliminar este Movimiento?"
            open={!!dialog} 
            onClose={handleDelete}
        />

      </PageBase>
  )
})

export default StockMoveEdit;