import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ContactAutocompleteObjectInput } from '../Contact/ContactAutocomplete';
import { Form } from '../../components'
import { getAPI, postAPI, activityLog } from '../../utils';
import { options_moneda , options_sino} from '../../appConfig';

const PartForm = ({doc={}, disabled=false, onClose, onCancel}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ proveeUnits, setProveeUnits] = useState([]);

    const currentUser = useSelector(state => state.currentUser);

    const optionsAll = useSelector(state => state.options) || {article_cat: []};
    const options_articulo_cate = optionsAll['articleCat'] || [];
    const options_articulo_subcate = optionsAll['articleSubCat'] || [];

    const units = useSelector(state => state.units);
    const warehouses = useSelector(state => state.warehouses);
    const getUnitOptions = useCallback( (deposito_udm) => {
        const unidades = []
        units.map( it => {
            if (it.baseUnit === deposito_udm) {
                unidades.push( {label: it.unit, value: it.unit} )
            }
        })
        return unidades;
    }, [])

    const base_units = [];
    units.map( it => {
        if (base_units.indexOf(it.baseUnit) == -1) {
            base_units.push(it.baseUnit);
        }
    })
    const warehouses_opts = warehouses.map(it => ({label: it.planta + "-" + it.codigo, value: it._id}))

    useEffect( () => {
        if (doc._id === 'new') {
            doc['activo'] = 'SI';
        }
    }, [doc])

    useEffect( () => {
        const unidades = getUnitOptions(doc.deposito_udm)
        setProveeUnits(unidades)
    }, [doc.deposito_udm, getUnitOptions]);
      
    const handleSubmit = (data, diffMgs) => {
        debugger
        let id = doc._id;
        if (id === 'new') {
            id = "";
            delete data._id
        }

        postAPI('/stock/parts/' + id, data)
        .then(res => {
            console.log(res.doc)
            enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'});
            if (doc._id === 'new') {
                const msg = "Se creó nuevo articulo";
                activityLog({label: "PART-" + doc.codigo, app:'stock', docId: doc._id, msg, currentUser})
            } else {
                activityLog({label: "PART-" + doc.codigo, app:'stock', docId: doc._id, diffMgs, currentUser})
            }

            if (onClose) onClose(res.doc)
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }

    const watchChanges = (value, name, type, getValues, setValue, setError, clearErrors) => {
        debugger
        if ( type==='change') {
            if (name === 'deposito_udm') {
                const unidades = getUnitOptions(value.deposito_udm)
                setProveeUnits(unidades)
            }
            if (name === 'precio') {
                if (getValues('precio') > 0 && getValues('moneda') === undefined) {
                    setError('moneda', {
                        type: 'manual',
                        message: 'You must be at least 18 years old',
                    })
                } else {
                    clearErrors('moneda')
                }
            }
        } 
    }

    const fields = [
        {
            label: 'Código',
            name: 'codigo',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4},
        },
        {
            label: 'Descripción',
            name: 'descripcion',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4},
        },
        {
            label: 'Marca',
            name: 'marca',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4},
        },
        {
            label: 'Categoría',
            name: 'categoria',
            options: options_articulo_cate,
            sizes: {xs:4},
        },
        {
            label: 'Subcategoría',
            name: 'subcategoria',
            options: options_articulo_subcate,
            sizes: {xs:4},
        },
        {
            label: 'Activo',
            name: 'activo',
            options: options_sino,
            sizes: {xs:4}
        },

        {
            label: 'Dimensión',
            name: 'dimension',
            sizes: {xs:4},
        },
        {
            label: 'UDC',
            name: 'dimension_udc',
            options: base_units.map( it => ({label: it, value: it})),
            sizes: {xs:4},
        },
        {
            label: 'Precio UDC',
            name: 'precio_udc',
            sizes: {xs:4},
        },
        {
            label: 'Deposito',
            name: 'divider',
            type: 'divider',
            sizes: {xs:12},
        },
        {
            label: 'Almacén Default',
            name: 'deposito_almacen._id',
            options: warehouses_opts,
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:3},
        },
        {
            label: 'Almacén Ubi1',
            name: 'deposito_ubi1',
            sizes: {xs:3},
        },
        {
            label: 'Almacén Ubi2',
            name: 'deposito_ubi2',
            sizes: {xs:3},
        },
        {
            label: 'Almacén Ubi3',
            name: 'deposito_ubi3',
            sizes: {xs:3},
        },
        {
            label: 'UDM',
            name: 'deposito_udm',
            options: base_units.map( it => ({label: it, value: it})),
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:3},
        },
        {
            label: 'Provee UDM',
            name: 'provee_udm',
            options: proveeUnits,
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:3},
        },
        {
            label: 'PESO (kg)',
            name: 'peso',
            sizes: {xs:3},
        },
        {
            label: 'Stock MIN',
            name: 'stock_min',
            sizes: {xs:3},
        },
        {
            label: 'Compras',
            name: 'divider',
            type: 'divider',
            sizes: {xs:12},
        },
        {
            label: "Provee",
            name: 'provee',
            Component: ({...other}) => ContactAutocompleteObjectInput({...other, provee: '1', withAdd: !disabled}),
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:3}
        },
        {
            label: 'LeadTime',
            name: 'provee_lead',
            sizes: {xs:3},
        },
        {
            label: 'Precio',
            name: 'precio',
            sizes: {xs:3},
        },
        {
            label: 'Moneda',
            name: 'moneda',
            options: options_moneda,
            sizes: {xs:3},
        },
    ]
  
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                disabled={disabled}
                watchChanges={watchChanges}
                onSubmit={handleSubmit}   
                //onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            // disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={false}
            />
        </Box>
    )
}
  
export default PartForm;