import React, {useState, useEffect} from 'react'; 
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox} from '../../../components'; 
import PlaceEditModal from './PlaceEditModal';
import {getAPI} from '../../../utils' ;
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const optionsTipo = [
    {label: 'BARRIO', value: 'BARRIO'},
    {label: 'LOCALIDAD', value: "LOCALIDAD"},
    {label: 'PARTIDO', value: "PARTIDO"},
    {label: 'PROVINCIA', value: "PROVINCIA"},
    {label: 'PAIS', value: "PAIS"},
]

const columns = (onEdit, onDelete) => ([
    {
        field: 'tipo',
        headerName: 'Tipo',
        type: 'string',
        width: 100,
    },
    {
        field: 'nombre',
        headerName: 'Nombre',
        type: 'string',
        width: 200,
    },
    {
        field: 'direccion',
        headerName: 'Direccion',
        sortable: false,
        width: 160,
    },
    {
        field: 'localidad',
        headerName: 'Localidad',
        sortable: false,
        width: 160,
    },
    {
        field: 'ciudad',
        headerName: 'Ciudad',
        sortable: false,
        width: 160,
    },
    {
        field: 'partido',
        headerName: 'Partido',
        sortable: false,
        width: 160,
    },
    {
        field: 'provincia',
        headerName: 'Provincia',
        sortable: false,
        width: 160,
    },
    {
        field: 'pais',
        headerName: 'Pais',
        sortable: false,
        width: 160,
    },
    {
        field: 'telefono',
        headerName: 'Telefono',
        type: 'string',    
        width: 170,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = () => {
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [tipo, setTipo]       = useState(searchParams.get("tipo") || "BARRIO");
    const [editDoc, setEditDoc] = useState("");
    const [reload, setReload]   = useState(false);
    const { reloadConfigs } = useUsersChannel();

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    // eslint-disable-next-line
    const [error, setError] = useState();
    const limit = 100;
    
    //let user = searchParams.get("status");

    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/settings/places/', {filter, tipo, skip: page*limit, limit})
        .then(data => {
            setLoading(false);
            data && data.list.forEach( it => it.id = it._id);
            setNum(data.num)
            setRows(data.list)
        })
        .catch(err => {
            console.error(err)
            setError(err)
        });

    }, [page, filter, tipo, reload])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleTipo = (ev) => {
        setTipo(ev.target.value)
    }
    const handleEdit = (item) => (ev) => {
        setEditDoc(item.row)
    }
    const handleDelete = (item) => (ev) => {

    }
    const handleNew = (ev) => {
        setEditDoc({_id: "new"})
    }
    const handleClose = (obj) => {
        debugger
        if (obj && obj.res) {
            setReload(true)
        }
        setEditDoc("")
    }

    const columnVisibilityModel = {
        // Hide columns status and traderName, the other columns will remain visible
        nombre: tipo === "BARRIO",
        direccion: tipo === "BARRIO",
        localidad: ['PARTIDO', 'PROVINCIA', 'PAIS'].indexOf(tipo) === -1,
        ciudad: ['PARTIDO', 'PROVINCIA', 'PAIS'].indexOf(tipo) === -1,
        partido: ['PROVINCIA', 'PAIS'].indexOf(tipo) === -1,
        provincia: ['PAIS'].indexOf(tipo) === -1,
        telefono: tipo === "BARRIO"
    }
    
    return (
        <PageBase title={
                    <>
                        <PageTitle>Lugares ({num})</PageTitle> 
                        <SearchBox label="Tipo" select options={optionsTipo} value={tipo} onChange={handleTipo}/>
                        <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                    </>
                }
                actions={
                    <>
                        <Button sx={{m:1}} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button>
                    </>
                }
                    
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(handleEdit, handleDelete)} 
                columnVisibilityModel={columnVisibilityModel}

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

            { editDoc && <PlaceEditModal tipo={tipo} editDoc={editDoc} onClose={handleClose}/> }    

        </PageBase>
    )
}

export default IndexPage;