import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 

import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
// import { Link } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';

import {PageBase, PageTitle, SearchBox, ExportButton, ResetButton} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce, postAPI, activityLog} from '../../utils' ;
import {UserSearchBox} from '../Settings/Users/UserSearchBox';
import OpportunityList from './OpportunityList';

import {options_opos_status, EXCEL_LIMIT} from "../../appConfig.js"
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

import moment from 'moment'

const optionsRevision = [
    {label: 'Todos', value: '-1'},
    {label: 'Sin Fecha', value: '-2'},
    {label: 'Vencidos', value: '0'},
    {label: 'Prox 7d', value: '1'},
    {label: 'Prox 30d', value: '2'},
    {label: 'Mayor 30d', value: '3'},
]

const optionsExito = [
    {label: 'Todos', value: '-1'},
    {label: 'Mayor a 75%', value: 75},
    {label: 'Mayor a 50%', value: 50},
    {label: 'Mayor a 25%', value: 25},
    {label: 'SIN',         value: '-2'}
]

const optionsIngresada = [
    {label: "Sin limite",   value: '-1'},
    {label: "Ult 7d",       value: 'last7d'},
    {label: "Ult mes",      value: 'lastMonth'},
    {label: "Ult año",      value: 'lastYear'},
]

const optionsOrden = [
    {label: 'Valor', value: 'valor'},
    {label: 'Nombre', value: 'nombre'},
    {label: 'Creado', value: 'created_at'},
    {label: 'Actualizado', value: 'updated_at'},
]

const defaultFilter = {
    filter:"", 
    status:"EN TRATATIVA", 
    exito:"-1", 
    ingresada:"-1", 
    distribuidor:"-1", 
    responsable:"-1", 
    revision: '-1', 
    orden: 'updated_at'
}

const IndexPage = forwardRef( ({isTabSelected}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [filter, setFilter] = useState(defaultFilter);
    const [reload, setReload] = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    let filtrosExt = useSelector(state => state.oppos);

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-edit'])

    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].
                                concat(canOpts.map(it => ({label:it.nombre, value:it._id}) ) ))
                                : [];

    const optionsStatus = ['TODOS','PARA APROBAR'].concat(options_opos_status).map( it => ({label: it, value: it}));
    
    const fn = useCallback(
        debounce(
            (page, filter, enqueueSnackbar) => {
                if (moment.isMoment(filter.fecha_desde)) {
                    filter.fecha_desde = moment(filter.fecha_desde).format('YYYY-MM-DD')
                }
                if (moment.isMoment(filter.fecha_hasta)) {
                    filter.fecha_hasta = moment(filter.fecha_hasta).format('YYYY-MM-DD')
                }
                setReload(false)
                setLoading(true)                
                getAPI('/crm/oppos/', {...filter, skip: page*limit, limit})
                .then(data => {
                    setLoading(false);
                    if (data && data.list) {
                        data && data.list.forEach( it => it.id = it._id);
                        setNum(data.num)
                        setRows(data.list)
                    }
                })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });
            }, 
        500),
    [])

    useEffect( () => {
        fn(page, filter, enqueueSnackbar)
    }, [page, filter, reload, isTabSelected, enqueueSnackbar, fn])

    useEffect( () => {
        setPage(0)
        setFilter({...defaultFilter, ...filtrosExt.filter})
    }, [filtrosExt.filter])

    const handleSearch = (tipo) => (ev) => {
        let valor
        if (tipo === 'fecha_desde' || tipo === 'fecha_hasta') {
            valor = moment(ev).format('YYYY-MM-DD')
        } else {
            valor = ev.target.value;
        }
        setFilter(v => ({...v, [tipo]: valor}))
    }

    const handleClick= tipo => item => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item)
    }

    const handleClear = (ev) => {
        setFilter(defaultFilter)
    }

    const handleFecha = (_id, tipo) => valor => {
        debugger
        postAPI('/crm/oppos/' + _id, {
            [tipo]: valor
        }).then( ({res, doc}) => {
            const msg = `Se cambió la ${tipo} a ${moment(valor).utc().format('YYYY-MM-DD')}"`
            enqueueSnackbar(msg, {variant: 'info'});
            activityLog({label: doc.nombre, app:'comercial', _id, msg, currentUser})
    
            setReload(true)
        }).catch(err => {
            enqueueSnackbar(err.message, {variant: 'err'})
        })
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Oportunidades`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Nombre', field: 'nombre'},
                    {title: 'Contacto', field: '_', transform: (v, doc) => {
                        if (doc.contacto) {
                            return doc.contacto.fullname;
                        }
                    }},
                    {title: 'Status', field: 'status'},
                    {title: 'Presupuesto', field: 'presupuesto'},
                    {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                    {title: 'Distribuidor', field: '_', transform: (v, doc) => doc.distributorId && doc.distributorId.codigo},
                    {title: 'Valor', field: 'valor'},
                    {title: 'Exito', field: 'exito'},
                    {title: 'Fecha Estimada', field: 'fecha_estimada', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    {title: 'Fecha Revision', field: 'fecha_revision', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    {title: 'Fecha Ingreso', field: 'fecha_ingreso', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    {title: 'Fecha Cerrada', field: 'fecha_cierre', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    {title: 'Material', field: 'material'},
                    {title: 'Color', field: 'color'},
                    {title: 'Direccion', field: 'direccion'},
                    {title: 'Partido', field: 'partido'},
                    {title: 'Obra', field: '_', transform: (v, doc) => doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}`  },
                    {title: 'Creado', field: 'created_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    // {title: 'id', field: '_id'}
            ],
            styles: {}
        }
    
        const rows = await getAPI('/crm/oppos/', {...filter, skip: page*limit, limit: 3*EXCEL_LIMIT})

        reportConfig['rows'] = rows.list;
    
        return reportConfig;
      }, [filter]);
    
    const columnVisibilityModel={
        actions: !!esLocalAdmin
    }

    return (
        <PageBase
            sx={{width:'100%', height: 'calc(100% - 95px)'}} 

            title={
                <>
                    <Hidden smDown>
                        <PageTitle>Oportunidades ({num})</PageTitle>
                    </Hidden>
                </>
            }
            toolbar={
                <Box>
                    <SearchBox sx={{ml:1, mr:2, flexGrow:1}} label="Nombre/Presu"  value={filter.filter} onChange={handleSearch('filter')}/>
                    <SearchBox label="Status" select multiple options={optionsStatus} value={filter.status} onChange={handleSearch('status')}/>
                    {filter.status === 'CERRADA' && 
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker 
                                sx={{m:1, width: '140px'}} 
                                label='Fecha Desde' 
                                size='small'
                                variant=''
                                value={filter.fecha_desde && moment(filter.fecha_desde)} 
                                onChange={handleSearch('fecha_desde')} 
                                format="YYYY-MM-DD" 
                                views={['day']} 
                                //error={!!error['fecha']} helperText={error['fecha']}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                            <DatePicker 
                                sx={{m:1, width: "140px"}} 
                                label='Fecha Hasta' 
                                size='small'
                                value={filter.fecha_hasta && moment(filter.fecha_hasta)} 
                                onChange={handleSearch('fecha_hasta')} 
                                format="YYYY-MM-DD" 
                                views={['day']} 
                                //error={!!error['fecha']} helperText={error['fecha']}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>                
                    }
                    <Hidden mdDown>
                        <UserSearchBox label="Responsable" vendedor value={filter.responsable} onChange={handleSearch('responsable')}/>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidores" select options={optionsDistrib} value={filter.distribuidor} onChange={handleSearch('distribuidor')}/>
                        <SearchBox label="Exito"     select options={optionsExito} value={filter.exito} onChange={handleSearch('exito')}/>
                    </Hidden>
                    <Hidden lgDown>
                        <SearchBox label="Revision"  select options={optionsRevision} value={filter.revision} onChange={handleSearch('revision')}/>
                        <SearchBox label="Ingresada" select options={optionsIngresada} value={filter.ingresada} onChange={handleSearch('ingresada')}/>
                        <SearchBox label="Ordenado" select options={optionsOrden} value={filter.orden} onChange={handleSearch('orden')}/>
                        <ResetButton onClick={handleClear}/>
                    </Hidden>
                </Box>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('OPPO')({id: true, _id: 'new'})}>Nuevo</Button>}
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app='comercial' withLabel/>
                </Box>
            }
       >           
            <OpportunityList
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                columnVisibilityModel={columnVisibilityModel}

                onClick={handleClick}
                onFecha={handleFecha}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;