import React, {useState, useEffect} from 'react';
//import {Link} from 'react-router-dom'
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//import Avatar from '@mui/material/Avatar';
//import InputBase from '@mui/material/InputBase';

import MenuIcon from '@mui/icons-material/Menu';
//import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import CircleIcon from '@mui/icons-material/Circle';
import AdbIcon from '@mui/icons-material/Adb';

import UserNotificationIcon from './UserNotificationIcon';
import {ProfileMenu, MobileNavigationMenu} from './NavigationMenus';
import { MessageBox } from '../../components';

import {checkUserRoles} from "../../utils";
import { useUsersChannel } from './WebSocketProvider';

const Navigation = ({appName='PruebaApp', children={}}) => {
    const org = useSelector( state => state.org );
    const currentUser = useSelector( state => state.currentUser );
    const authenticated = useSelector( state => state.authenticated );
    const appVersion = useSelector( state => state.appVersion);
    const [previousVersion, setPreviousVersion] = useState(null);
    const [dialog, setDialog] = useState(false);
    const {isReady} = useUsersChannel();

    const navigate = useNavigate();
    const location = useLocation();
    const configDisabled = !checkUserRoles(currentUser, ['admin', 'local-admin', 'administracion'])

    useEffect(() => {
        if (!isReady || !appVersion) return;
        if (previousVersion === null) {
          // On initial load, set the previous version
          setPreviousVersion(appVersion);
        } else if (appVersion !== previousVersion) {
          // If appVersion changes, show the MessageBox
          setDialog(true);
          setPreviousVersion(appVersion);
        }
    }, [appVersion, previousVersion]);

    const handleReloadApp = (st) => () => {
        setDialog(false)
        window.location.reload();
        // navigate(location.pathname, { replace: true });
    }

    // borra navigation para que la pagina de login quede sola
    if (!authenticated || !currentUser.active ) return "";

    const color = isReady > 0 ? '#4dcf81' : '#f22'

    const _debug = false
    return (
        <AppBar sx={{ flexGrow: 1, '@media print': {display: 'none'} }} position="static">
            <Toolbar variant='dense'>

                <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

                {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton> */}

                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                    {appName}/{org}

                    { authenticated && <CircleIcon sx={{ml:0.5, pt:0.9, color}}/>}
                </Typography>

                <Box sx={{ flexGrow: 0.05 }} />

                {children}
                
                <Box sx={{ flexGrow: 1 }} />
                
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                    {/* <UserNotificationIcon/> */}

                    { !configDisabled && 
                        <IconButton 
                            size="large" 
                            color="inherit"
                            onClick={() => navigate("/settings", {replace: true})}
                        >
                            <SettingsIcon />
                        </IconButton>
                    }
                    
                    <ProfileMenu/>
                </Box>
            
                <MobileNavigationMenu/>

                <MessageBox 
                    title={"Nueva versión de App"}
                    open={dialog} 
                    maxWidth='sm'
                    onCancel={handleReloadApp(false)}
                    onClose={handleReloadApp(true)}
                >
                    Se actualizó la aplicación a v{appVersion}!<br/>
                    Apriete Aceptar para actualizar!
                </MessageBox>

            </Toolbar>
        </AppBar>
    )
}

export default Navigation;