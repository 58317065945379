import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputDropZone from '../../components/DropZone/InputDropZone';
import {SERVER_ATTACHMENT_PATH} from '../../appConfig.js';
import { deleteAPI } from '../../utils/API.js';



const FileList = ({
    docs=[], 
    editable=false, 
    downloadable=true,
    folder="/comercial/pres", 
    emptyMessage="Sin archivos", 
    onDelete, 
    onUploaded=()=>{}, 
    ...other
}) => {
    const [lista, setLista] = useState(docs);

    const org = useSelector(state => state.org);

    useEffect( () => {
        setLista(docs)
    }, [docs])

    const handleDelete = (it) => async (ev) => {
        debugger
        console.log(it)
        // primero borra el archivo logico
        if (onDelete) {
            // OBS es importante que onDelete haga returno con un Promise!!!!
            await onDelete(it)({})
        }
        // segundo borra el archivo fisico 
        await deleteAPI("/attachments/" + it._id).catch(err => {
            console.error(err)
        })
    }

    const link = (attachment) => {
        if (!attachment) {
            return ""
        }
        const href = SERVER_ATTACHMENT_PATH + "/" + org + "/" + attachment.path + attachment.filename;
        // return <a href={href} target1='_blank' rel='noreferrer'>{attachment.filename}</a>

        // return <Link to={href} reloadDocument>{attachment.filename}</Link>
        return downloadable ? <a href={href} target="_blank">{attachment.filename}</a> : attachment.filename
        // return downloadable ? <a href={href} >{attachment.filename}</a> : attachment.filename
    }
    const border = editable ? {border: '1px solid', padding: '8px'} : {overflow: 'scroll', height: 67}

    return (
        <Box className='MuiFileList-root' style={border} {...other}>
            <ul>
            { lista.length ? 
                lista.map(it => 
                    (it && it.filename && 
                        <li key={it.filename} style={{marginLeft:10, display: 'block'}}>
                            {link(it)}
                            { editable && 
                                <IconButton onClick={handleDelete(it)}>
                                    <DeleteIcon fontSize='small'/>
                                </IconButton>
                            }
                        </li>
                    )
                ) 
                : 
                emptyMessage
            }
            { editable && <InputDropZone label="Arrastre o seleccione" withList multiple folder={folder} onUploaded={onUploaded}/>}
            </ul>
        </Box>
    )
    
}

export default FileList;