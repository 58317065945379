import React, {useState, useEffect, useMemo} from 'react';
import { useSnackbar } from 'notistack';
import {Table} from '../../components';
import {getAPI} from '../../utils';
import moment from 'moment';

export const POrderTable = ({tipo='NP', obraId='', pvId='', ofId='', onClick=()=>{}}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [docs, setDocs] = useState([])
debugger
    const cols = useMemo(() => [
        {   name: tipo, 
            field: v=> <a href='#' onClick={onClick(v)}>{v['np']}</a>,           
            th: { align:'center'},
            td: { align:'center'}, 
        },
        {   name: 'NP Proveedor', 
            field: v=> v['np_provee'],    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Proveedor', 
            field: v=> v['provee'] && `[${v['provee']['codigo_proveedor']}] ${v['provee']['razon_social']}`,    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Descripcion', 
            field: v=> v['descripcion'],    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        // {   name: 'Categoria', 
        //     field: v=> v['categoria'],    
        //     th: { align:'center'},
        //     td: { align:'center'}, 
        // }, 
        {   name: 'Status',    
            field: v=> v['status'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Entrega',    
            field: v=> moment(v['fecha_entrega']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Recibido',    
            field: v=> v['fecha_recibido'] && moment(v['fecha_recibido']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'OF',    
            field: v=> v['of'] && v['of']['of'], 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
    ], [tipo, onClick])
    
    useEffect( () => {
        debugger
        const q = {tipo};
        if (obraId) {
            q['queryName'] = 'byObras';
            q['obraId'] = obraId;
        }
        if (pvId) {
            q['queryName'] = 'byPv';
            q['pvId'] = pvId;
        }
        if (ofId) {
            q['queryName'] = 'byOf';
            q['ofId'] = ofId;
        }
        getAPI('/stock/porders/', q)
        .then(data => {
            if (data) setDocs(data.list)
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [tipo, obraId, pvId, ofId, enqueueSnackbar])

    return (
        <Table 
            //sx={{width: "800px", p:1, m:1}}
            cols={cols}
            data={docs}
            tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
            headerProps={{sx: {fontWeight:'bold'}}}
            rowProps={{sx: {m:1}}}
        />        
    )
}

export default POrderTable